import { 
	MEDS_URL,
	} from './routes-api';
import {callApiWithJwtToken} from "./api";

export const getMedications = async () => {
	return callApiWithJwtToken({
		url: MEDS_URL + 'GetMedications',
		needsAuth: false,
		method: 'GET'
	});
};

export const getDrugModels = async (obj: any) => {
	return callApiWithJwtToken({
		url: MEDS_URL + 'GetDrugModels',
		body: obj,
		needsAuth: false,
		method: 'POST'
	});
};