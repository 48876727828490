import React from 'react';
import NavigationBarKitsList from '../navigation-bar/NavigationBarKitsList';
import withRouter from "../../helpers/withRouter";

function NavigationBarButtons(props) {
	const viewName = props.pathname;

	return (
		<>
			<NavigationBarKitsList
				userDetails={null}
				mainViewName={viewName}
			/>
			<div className='ref-body'
				style={{
					height: '100%'
					,overflow:'auto'
				}}
			>
				{props.children}
			</div>
			<footer
				style={{
					backgroundColor: '#00274c'
					, color: 'white'
					, position: 'fixed'
					, bottom: '0'
					, width: '100%'
				}}
			>
				Copyright © 2024-2025 Regents of the University of Michigan. All rights reserved.
			</footer>	
			
		</>
	);
}

export default withRouter(NavigationBarButtons);