import React from 'react';
import NavigationBarLayout from '../navigation-bar/NavigationBarLayout';

function NavigationBarButtonless(props) {

	return (
		<>
			<NavigationBarLayout
				
			/>			
			<div className='ref-body'
				style={{
					height: '100%'
					, overflow: 'auto'
				}}
			>
				{props.children}
			</div>

			<footer
				style={{
					backgroundColor: '#00274c'
					, color: 'white'
					, position: 'fixed'
					, bottom: '0'
					, width: '100%'
				}}
			>
				Copyright © 2024-2025 Regents of the University of Michigan. All rights reserved.
			</footer>	
		</>
	);
}

export default NavigationBarButtonless;