import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

interface PopoutWindowComponent {
    modalOpen: boolean, 
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const PopoutWindow: React.FC<PopoutWindowComponent> = ( {modalOpen, setModalOpen} ) => {
    // const [modalOpen, setModalOpen] = useState(false);
  
    const toggleModal = () => {
      setModalOpen(!modalOpen);
    };
  
    return (
      <div>
        <Button color="primary" onClick={toggleModal}>Input Warning!</Button>
        <Modal isOpen={modalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Input Warning!</ModalHeader>
          <ModalBody>
            {/* Content of your popout window */}
            You must make sure that the height is between 10 and 200 cms, or 3.94 and 78.74 inches.
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  };
  
  export default PopoutWindow;
