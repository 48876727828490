import {AUTHENTICATION_LINK_URL} from "./routes-api";
import {getHeadersAuth, headers} from "./headers";
import {getCookie, setCookie} from "../helpers/helpers";
import Cookies from "js-cookie";

let isRefreshingToken = false;

export async function callApiWithJwtToken(definition: { [x: string]: any; url: string; body?: any; needsAuth?: boolean; method?: string; }): Promise<any> {
    const response = await fetch(definition['url'], {
        method: definition['method'] === "POST" ? 'POST' : 'GET',
        headers: definition['needsAuth'] ? getHeadersAuth() : headers,
        body: definition['body'] != null ? JSON.stringify(definition['body']) : undefined,
        credentials: "include"
    }).then(resp => {
        console.log('INRESPONSE', resp)
        if(resp.ok) {
            return {
                response: resp,
                json: resp.json()
            }
        }
        return {
            response: resp,
            json: null
        };
    }).catch(err => {
        // Object.keys(Cookies.get()).forEach(cookieName => {
        //     console.log('removeCookie', cookieName)
        //     Cookies.remove(cookieName);
        // });
        // window.location.href = '/login';
    });

    console.log('RESPONSE', response)
    if(response === null) {
        window.location.href = '/login';
    }
    if(response == null) {
        Object.keys(Cookies.get()).forEach(cookieName => {
            console.log('removeCookie', cookieName)
            Cookies.remove(cookieName);
        });
        return {bSuccess: false, oReturnObject: null, sErrorInfo: 'API connection error'};
    }

    // console.log('response1', definition, response.response)

    if (response.response.status === 403 || response.response.status === 401) {
        if (isRefreshingToken) {
            // Another call is already refreshing the token, wait for it to finish
            await new Promise(resolve => setTimeout(resolve, 1000));
            return callApiWithJwtToken(definition);
        } else {
            isRefreshingToken = true;
        }

        try {
            // Attempt to refresh the JWT token using the refresh token
            const refreshResponse = await fetch(`${AUTHENTICATION_LINK_URL}RefreshTokenUser`, {
                method: 'POST',
                headers: getHeadersAuth(),
                body: JSON.stringify({}),
                credentials: "include"
            }).then(resp => {
                if(resp.status === 403 || resp.status === 401) {
                    return {
                        response: resp,
                        json: null
                    }
                }
                return {
                    response: resp,
                    json: resp.json()
                }
            });

            if (refreshResponse.response.ok) {
                // console.log('options2', options)
                // console.log('403')
                return await fetch(definition['url'], {
                    method: definition['method'] === "POST" ? 'POST' : 'GET',
                    headers: definition['needsAuth'] ? getHeadersAuth() : headers,
                    body: definition['body'] != null ? JSON.stringify(definition['body']) : undefined,
                    credentials: 'include'
                }).then(resp => resp.json());
            } else {
                //navigate to login page and wipe out the refresh and jwt token
                Object.keys(Cookies.get()).forEach(cookieName => {
                    console.log('removeCookie', cookieName)
                    Cookies.remove(cookieName);
                });
                console.log('could not refresh token')
                return false
                // window.location.href = '/login';
            }
        } finally {
            isRefreshingToken = false;
        }
    } else {
        // console.log('no403')
        return await response.json;
    }
}

const setValue = (keyName: string, newValue: string) => {
    try {
        setCookie(keyName, newValue, true);
    } catch (err) {}
    return newValue;
};
const getValue = (keyName: string, defaultValue: string) => {
    try {
        const value = getCookie(keyName, false);
        if (value) {
            return JSON.parse(value);
        } else {
            setCookie(keyName, defaultValue, true);
            return defaultValue;
        }
    } catch (err) {
        return defaultValue;
    }
}