import React, { Component } from 'react';
import Plotly from 'plotly.js-dist-min';
import {PKDrugModel, TCI} from "../../../../model/types";
import {Button} from "reactstrap";

interface LineGraphProps {
    tci: TCI;
    tci_enabled_count: number;
    tci_unit: string;
    drug: string;
    drugModels: PKDrugModel[];
    medsList: {[index: string]:any};
    referenceTime: Date;
    effectSiteStyle: string;
    plasmaStyle: string;
    modelName: string;
    concentrationTarget: string;
    expandGraph: (med: string) => void
    fullSize: boolean;
    maxTime: number;
}

class Graph extends Component<LineGraphProps> {
    private readonly graphRef: React.RefObject<HTMLDivElement>;

    constructor(props: LineGraphProps) {
        super(props);
        this.graphRef = React.createRef();

        this.state = {
            tci: null,
            med: null
        }
    }

    componentDidMount() {
        this.createGraph();
        console.log('zzzMedsList', this.props.drug, this.props.medsList, this.props.tci)
    }

    componentDidUpdate(prevProps: LineGraphProps, prevState: any, snapshot: any) {
        if(
            this.props.tci !== prevProps.tci
            || this.props.tci_enabled_count !== prevProps.tci_enabled_count
            || this.props.plasmaStyle !== prevProps.plasmaStyle
            || this.props.effectSiteStyle !== prevProps.effectSiteStyle
            || this.props.fullSize !== prevProps.fullSize
            || this.props.referenceTime !== prevProps.referenceTime
            || this.props.medsList !== prevProps.medsList
            || this.props.drug !== prevProps.drug
            || this.props.modelName !== prevProps.modelName
            || this.props.maxTime !== prevProps.maxTime
            || this.props.concentrationTarget !== prevProps.concentrationTarget
        ) {
            this.createGraph();
            console.log('updated')
        }
    }

    findIndexClosestToX(x: number, arr: number[]) {
        let closest = arr[0];
        let diff = Math.abs(x - closest);
        for (let val of arr) {
            let newdiff = Math.abs(x - val);
            if (newdiff < diff) {
                diff = newdiff;
                closest = val;
            }
        }
        return arr.indexOf(closest);
    }

    createGraph() {
        // const { data, shadingStartY, shadingEndY } = this.props;
        const {effectSiteStyle, plasmaStyle, drug, tci_unit, medsList, modelName, fullSize, referenceTime, maxTime, concentrationTarget} = this.props;

        const matchedMed = medsList.filter((med: {Medication_Name: string}) => med.Medication_Name === drug)[0];

        console.log('graphMed', matchedMed)

        const tci = this.props.tci;

        if(tci == null) return;

        // date.now set time to reference time which is string of hh:mm am/pm
        // const minutes = parseInt(this.props.referenceTime.split(':')[1].split(' ')[0]);
        // const ampm = this.props.referenceTime.split(':')[1].split(' ')[1];
        // const hours = parseInt(this.props.referenceTime.split(':')[0]) + (ampm === 'PM' ? 12 : 0);
        // const referenceTime = (!!this.props.anesthesiaStart ? this.props.anesthesiaStart : new Date(Date.now())).setHours(hours, minutes, 0, 0);

        // max value of t - maxTime second
        console.log('targetT', tci.t[tci.t.length - 1], tci.t[tci.t.length - 1] - maxTime)
        const tIndex =  this.findIndexClosestToX(tci.t[tci.t.length - 1] - maxTime, tci.t);

        console.log('tIndex', tIndex, tci.t[tIndex]);

        // Every value from index 0 to tIndex including tIndex
        const cpSolidValues = tci.Cp.slice(0, tIndex);
        // Every value from tIndex to end invluding tIndex
        const cpDashedValues = tci.Cp.slice(tIndex, tci.Ce.length);

        // Every value from index 0 to tIndex including tIndex
        const ceSolidValues = tci.Ce.slice(0, tIndex);
        // Every value from tIndex to end invluding tIndex
        const ceDashedValues = tci.Ce.slice(tIndex, tci.Ce.length);

        const ceSolid = {
            x: ceSolidValues.map((_, index) => new Date(referenceTime.getTime() + ((index/60.0) * 60000))),
            y: ceSolidValues,
            mode: 'lines',
            type: 'scatter',
            line: {
                shape: 'spline',
                color: 'blue'
            },
            name: 'Effect Concentration (Ce)',
            showlegend: plasmaStyle !== 'Hide'
        };

        const ceDashed = {
            x: ceDashedValues.map((_, index) => new Date(referenceTime.getTime() + (((index+tIndex)/60.0) * 60000))),
            y: ceDashedValues,
            mode: 'lines',
            type: 'scatter',
            line: {
                dash: 'dot',
                width: 4,
                color: 'blue'
            },
            name: 'Effect Concentration (Ce)',
            showlegend: false
        };

        const cpSolid = {
            x: cpSolidValues.map((_, index) => new Date(referenceTime.getTime() + ((index/60.0) * 60000))),
            y: cpSolidValues,
            mode: 'lines',
            type: 'scatter',
            line: {
                shape: 'spline',
                color: 'orange'
            },
            name: 'Plasma Concentration (Cp)',
            showlegend: effectSiteStyle !== 'Hide'
        };

        const cpDashed = {
            x: cpDashedValues.map((_, index) => new Date(referenceTime.getTime() + (((index+tIndex)/60.0) * 60000))),
            y: cpDashedValues,
            mode: 'lines',
            type: 'scatter',
            line: {
                dash: 'dot',
                width: 4,
                color: 'orange'
            },
            name: 'Plasma Concentration (Cp)',
            showlegend: false
        };

        const dataArr = [];

        if(plasmaStyle !== 'Hide') {
            dataArr.push(cpSolid);
            dataArr.push(cpDashed);
        }
        if(effectSiteStyle !== 'Hide') {
            dataArr.push(ceSolid);
            dataArr.push(ceDashed);
        }

        // const hoverRegion = {
        //     x: tci.Ce.map((_, index) => new Date(referenceTime.getTime() + (((index)/60.0) * 60000))),
        //     y: tci.Ce.map((_, index) => (matchedMed['Lower'] + matchedMed['Upper']) / 2),
        //     mode: 'lines',
        //     type: 'scatter',
        //     line: {
        //         dash: 'dot',
        //         width: 0,
        //         color: 'orange'
        //     },
        //     // hoverinfo: 'text',
        //     // text: 'Optimal Range',
        //     hovertemplate: '<b>Patient Stays Unconscious</b>',
        //     showlegend: false,
        //     name: 'Effect'
        // }
        //
        // if(concentrationTarget === 'Show') {
        //     dataArr.push(hoverRegion);
        // }

        const shadedRegion = {
            type: 'rect',
            xref: 'paper',
            yref: 'y',
            x0: 0,
            x1: 1,
            y0: matchedMed['Lower'],
            y1: matchedMed['Upper'],
            fillcolor: 'rgba(0,168,220,0.3)',
            line: { width: 0 },
        }

        const layout = {
            yaxis : {
                title: `Concentration (${tci_unit})`,
                rangemode: 'tozero',
            },
            margin: {
                r: 10,
                pad: 4
            },
            title: `${drug} ${!fullSize ? '' : `<br /> Model: ${modelName} <br />`} `,
            shapes: concentrationTarget === 'Show' ? [shadedRegion] : [],
            paper_bgcolor: 'white',
            plot_bgcolor: 'white',
            // annotations: [{
            //     x: 0.5,
            //     y: (matchedMed['Lower'] + matchedMed['Upper']) / 2,
            //     xref: 'paper',
            //     yref: 'y',
            //     text: 'Therapeutic Range',
            //     showarrow: false,
            //     font: {
            //         size: 16,
            //         color: 'black'
            //     },
            //     align: 'center',
            //     bgcolor: 'rgba(255, 255, 255, 0.8)',
            //     bordercolor: 'black',
            //     borderwidth: 1,
            //     opacity: 0,
            //     hovertext: 'Therapeutic Range2',
            //     hoverlabel: { bgcolor: 'black', bordercolor: 'black', font: { color: 'white' } }
            // }]
        };

        console.log("graph data", dataArr)

        // @ts-ignore
        Plotly.newPlot(this.graphRef.current, dataArr, layout, {displayModeBar: false, responsive: true});
    }

    render() {
        const {drug, expandGraph, fullSize, tci_enabled_count} = this.props;
        return (
        <>
            {/*<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>*/}
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}} className={'plot-container'}>
                <div style={{height: '400px', flexGrow: '1'}} ref={this.graphRef} className={'plotly-graph'}></div>
                { !fullSize &&
                    <Button className={'title-button'} color={'primary'} style={{marginRight: '10px'}} onClick={() => expandGraph(drug)}>Preview Changes</Button>
                }

            </div>
        </>);
    }
}

export default Graph;
