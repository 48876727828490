import {getCookie} from "../helpers/helpers";

export const headers = {
	'content-type': 'application/json'
	, 'content': 'application/json'
};

export const getHeadersAuth = () =>{
	// const jwtToken = getCookie(process.env.REACT_APP_JWT || '', true);
	// console.log("jwtToken Read", jwtToken)
	// const bearer = `Bearer ${jwtToken}`;

	return {
		'content-type': 'application/json'
		, 'content': 'application/json'
		// , 'Authorization': bearer
	};
}