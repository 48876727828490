import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

interface DisclaimerPopoutWindowComponent {
    // modalOpen: boolean, 
    // setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    className?: string | null
}

const DisclaimerPopoutWindow: React.FC<DisclaimerPopoutWindowComponent> = ( ) => {
    // const [modalOpen, setModalOpen] = useState(false);
  
    const toggleModal = () => {
      // setModalOpen(!modalOpen);
      window.localStorage.setItem("disclaimer_agreement", "agree");
      window.location.href = `/`;
    };
  
    return (
      <div>
        <Modal isOpen={true} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Disclaimer</ModalHeader>
          <ModalBody>
          <p className='tw-text-lg'>This software performs pharmacokinetic simulations based on peer-reviewed mathematical models to aid clinicians and researchers in understanding model implications. It serves as an advisory tool. The data integration and application of these models to individual patients requires clinical judgment by healthcare providers. The calculations and information provided are intended as aids for healthcare professionals and should be independently verified before making clinical decisions. By using this software, users release the creators and developers from any liability for direct, indirect, incidental, consequential, or special damages arising from or in any way connected with the use of this software or the information provided herein.</p>
          <br/>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>I Agree</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  };
  
  export default DisclaimerPopoutWindow;