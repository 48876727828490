import { callApiWithJwtToken } from "./api";
import { EXTERNAL_LOG_URL, RECAPTCHA_URL } from "./routes-api";

export const VerifyRecaptcha = async (obj: any) => {
	return callApiWithJwtToken({
		url:  RECAPTCHA_URL,
		body: obj,
		needsAuth: false,
		method: 'POST'
	});
};

export const ExternalUserLog = async (obj: any) => {
	return callApiWithJwtToken({
		url:  EXTERNAL_LOG_URL,
		body: obj,
		needsAuth: false,
		method: 'POST'
	});
};