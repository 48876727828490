import React, { Fragment, useEffect } from 'react';
import { Input, Button, Table } from 'reactstrap';
import { FaTrash } from 'react-icons/fa';
import { DoseTable, DoseTableRow } from "../../../../model/types";
import { getMinDiff } from "../../../../helpers/med-helpers";
import { DateTimePicker } from "../../../helper-components/misc/DateTimePicker";
interface DoseTableProps {
    medTable: DoseTable;
    setMedTable: (medTable: DoseTable) => void;
    listData: { [index: string]: any };
    referenceTime: Date;
    onSave: () => void;
    onRemoveMed: (med: string) => void;
    medsList: { [index: string]: any };
    index: number;
    lockedDoseTable: boolean;
    startWithNewRow?: boolean;
    showDelete: boolean;
    LogUserActivity?: (medTablesNew: any) => void;
}

const DoseTableComponent: React.FC<DoseTableProps> = ({
    medTable,
    listData,
    setMedTable,
    referenceTime,
    onSave,
    onRemoveMed,
    medsList,
    index,
    lockedDoseTable,
    startWithNewRow,
    showDelete,
    LogUserActivity
}) => {
    useEffect(() => {
        if (startWithNewRow) {
            onAddRow();
        }
    }, []);

    useEffect(() => {
        console.log('medTable', medTable)
    }, [medTable]);

    const onAddRow = () => {
        let currentTime = new Date();

        const firstEvent = medTable.rows.length > 0 ? medTable.rows[0] : null;
        const firstEventTime = firstEvent ? firstEvent.time : currentTime;

        // If the day is different, set the time to the reference time
        let newTime = currentTime;
        if (currentTime.toDateString() !== referenceTime.toDateString() && medTable.rows.length > 0) {
            newTime = new Date(medTable.rows[medTable.rows.length - 1].time);
        }

        // Num of minutes between time and referenceTime both times are in HH:MM format
        const timeSinceFirstEvent = getMinDiff(currentTime, firstEventTime);

        const medRow: DoseTableRow = {
            event: '-- Choose Event --',
            time: newTime,
            timeSinceFirstEvent,
            timeToRun: 0,
            dose: 0,
            unit: listData['DoseTable-Units'][0]['List_Value']
        }
        setMedTable({ ...medTable, rows: [...medTable.rows, medRow] });
    }

    const onChange = (index: number, field: string, value: any, bolusUnits: string[] = [], infusionUnits: string[] = []) => {
        console.log('onChange', index, field, value, bolusUnits, infusionUnits)
        const updatedTable = medTable;

        if (field === 'time') {
            console.log('test', value, referenceTime)
            updatedTable.rows[index].timeSinceFirstEvent = getMinDiff(value, referenceTime);
        }
        if(field === 'event' && value === 'Bolus') {
            updatedTable.rows[index].timeToRun = 5.0/60.0;
            const wasInfusion = updatedTable.rows[index].event === 'Stop Infusion' || updatedTable.rows[index].event === 'Start Infusion' || updatedTable.rows[index].event === 'Dose Change' || updatedTable.rows[index].event === '-- Choose Event --';
            if(bolusUnits.length !== 0 && wasInfusion) {
                updatedTable.rows[index].unit = bolusUnits[0];
            }
        } else if(field === 'event' && (value === 'Stop Infusion' || value === 'Start Infusion' || value === 'Dose Change')) {
            const wasBolus = updatedTable.rows[index].event === 'Bolus' || updatedTable.rows[index].event === '-- Choose Event --';
            if (value === 'Stop Infusion') {
                updatedTable.rows[index].dose = 0;
            }
            if (infusionUnits.length !== 0 && wasBolus) {
                updatedTable.rows[index].unit = infusionUnits[0];
            }
        } else {
            updatedTable.rows[index].timeToRun = 0;
        }
        updatedTable.rows[index][field] = value;
        console.log('updatedTable', updatedTable)
        setMedTable(updatedTable);
    }

    const onDeleteRow = (index: number) => {
        const updatedTable = medTable;
        updatedTable.rows.splice(index, 1);
        setMedTable(updatedTable);
    }

    return (
        <>
            <Table bordered striped responsive>
                <thead>
                    <tr>
                        <th>Med Event</th>
                        <th style={{ width: '30%' }}>Time Recorded</th>
                        <th style={{ width: '10%' }}>Dose</th>
                        <th>Units</th>
                        {showDelete &&
                            <th>Delete</th>
                        }
                    </tr>
                </thead>
                <tbody>

                    {medTable != null && medTable.rows.map((row, index) => {
                        const med = medsList.filter((med: {
                            Medication_Name: string
                        }) => med.Medication_Name === medTable.med)[0];
                        const infusionUnits = med.Infusion_Units;
                        const bolusUnits = med.Bolus_Units;
                        let units: string[] = []
                        if (row.event === 'Bolus') {
                            units = bolusUnits;
                        } else if (row.event === 'Stop Infusion' || row.event === 'Start Infusion' || row.event === 'Rate Change') {
                            units = infusionUnits;
                        }
                        const lastRow = index > 0 ? medTable.rows[index - 1] : medTable.rows[0];
                        const lastRowTime = lastRow.time;
                        const currentRowTime = row.time;
                        const showRefRow = index > 0 && lastRowTime !== null && lastRowTime < referenceTime && row.time !== null && row.time >= referenceTime
                        return (
                            <Fragment key={`GTC-${medTable.model_id}-${index}`}>
                                {showRefRow &&
                                    <tr key={`h${index}`}>
                                        <td>
                                            <hr style={{ color: 'red' }} />
                                        </td>
                                        <td style={{ color: 'red' }}>
                                            Reference
                                            Time: {referenceTime.toDateString()} {referenceTime.toLocaleTimeString('en-US', {
                                                hour: '2-digit',
                                                minute: '2-digit'
                                            })}
                                        </td>
                                        <td>
                                            <hr style={{ color: 'red' }} />
                                        </td>
                                        <td>
                                            <hr style={{ color: 'red' }} />
                                        </td>
                                        <td>
                                            <hr style={{ color: 'red' }} />
                                        </td>
                                    </tr>
                                }
                                <tr key={index}>
                                    <td>
                                        <Input
                                            type="select"
                                            value={row.event}
                                            onChange={(e) => onChange(index, 'event', e.target.value, bolusUnits, infusionUnits)}
                                            disabled={lockedDoseTable}
                                        >
                                            {listData.hasOwnProperty('DoseTable-Events') && listData['DoseTable-Events'].map((event: {
                                                List_Value: string
                                            }) => (
                                                <option key={event.List_Value}
                                                    value={event.List_Value}>{event.List_Value}</option>
                                            ))}
                                        </Input>
                                    </td>
                                    <td>
                                        <DateTimePicker
                                            value={row.time === null ? new Date() : row.time}
                                            onChange={(date: any) => onChange(index, 'time', new Date(date), bolusUnits, infusionUnits)}
                                            disabled={lockedDoseTable}
                                        />
                                    </td>
                                    <td>
                                        <Input
                                            type="number"
                                            value={row.dose}
                                            onChange={(e) => onChange(index, 'dose', parseFloat(e.target.value))}
                                            disabled={row.event === '-- Choose Event --' || row.event === 'Stop Infusion' || lockedDoseTable}
                                        />
                                    </td>
                                    <td>
                                        <Input
                                            type="select"
                                            value={row.unit}
                                            onChange={(e) => onChange(index, 'unit', e.target.value)}
                                            disabled={row.event === '-- Choose Event --' || row.event === 'Stop Infusion' || lockedDoseTable}
                                        >
                                            {units.map((unit: string) => (
                                                <option key={unit} value={unit}>{unit}</option>
                                            ))}
                                        </Input>
                                    </td>
                                    {showDelete &&
                                        <td style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}> {/* Center the button */}
                                            <Button disabled={lockedDoseTable} color="danger" onClick={() => onDeleteRow(index)}
                                                className='tw-h-10'>
                                                <FaTrash />
                                            </Button>
                                        </td>
                                    }
                                </tr>
                                {index === medTable.rows.length - 1 && currentRowTime !== null && referenceTime > currentRowTime &&
                                    <tr key={`t${index}`}>
                                        <td>
                                            <hr style={{ color: 'red' }} />
                                        </td>
                                        <td style={{ color: 'red' }}>
                                            Reference
                                            Time: {referenceTime.toDateString()} {referenceTime.toLocaleTimeString('en-US', {
                                                hour: '2-digit',
                                                minute: '2-digit'
                                            })}
                                        </td>
                                        <td>
                                            <hr style={{ color: 'red' }} />
                                        </td>
                                        <td>
                                            <hr style={{ color: 'red' }} />
                                        </td>
                                        <td>
                                            <hr style={{ color: 'red' }} />
                                        </td>
                                    </tr>
                                }
                            </Fragment>
                        )
                    })}
                    {/*<tr style={{height: '150px'}}/>*/}
                </tbody>
            </Table>
            {(medTable == null || medTable.rows.length === 0) &&
                <div>
                    <h1 style={{ color: 'orange' }}>Add a Med Event</h1>
                </div>
            }
            {
                medTable.failure_reason &&
                <div style={{ backgroundColor: 'red', color: 'white', marginBottom: '10px', textAlign: 'center' }}>
                    {medTable.failure_reason}
                </div>
            }
            {!lockedDoseTable &&
                <>
                    <Button color="primary" onClick={onAddRow}>
                        Add Event
                    </Button>
                    <Button color="success" onClick={() => {
                        onSave(); 
                        // if (!!LogUserActivity) {
                        //     setTimeout(() => {
                        //         LogUserActivity();
                        //     }, 500);
                        // }
                        }
                    }
                        style={{ right: '0px', marginLeft: '70px' }} id={`save-${index}`}>
                        Save
                    </Button>
                    {!startWithNewRow &&
                        <Button color="danger" onClick={() => onRemoveMed(medTable.med)} style={{ right: '0px', float: 'right' }}>
                            Remove Med
                        </Button>
                    }
                </>
            }
        </>
    );
};

export default DoseTableComponent;
