import React from 'react';
import {setErrorLog} from "../../../api/logs-api";

function ErrorPage({ error }) {
    let errorMessage = '';
    let additionalMessage = '';

    switch (error.status) {
        case 401:
            errorMessage = '401 - Unauthorized: You are not authorized to access this page.';
            additionalMessage = 'Please check your credentials or contact an administrator.';
            break;
        case 500:
        case 501:
        case 502:
        case 503:
            errorMessage = 'An unexpected server error occurred. Please try again later.';
            break;
        default:
            errorMessage = '404 - Page Not Found: The page you requested could not be found.';
            break;
    }

    return (
        <div
            style={{
                fontFamily: 'Arial, sans-serif',
                backgroundColor: '#f4f4f4',
                margin: 0,
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                textAlign: 'center',
            }}
        >
            <div>
                <h1
                    style={{
                        fontSize: '4rem',
                        color: '#333',
                        marginBottom: '2rem',
                    }}
                >
                    {error.status}
                </h1>
                <p
                    style={{
                        fontSize: '1.6rem',
                        color: '#666',
                        marginBottom: '2rem',
                    }}
                >
                    {errorMessage}
                </p>
                {additionalMessage && (
                    <p
                        style={{
                            fontSize: '1.6rem',
                            color: '#666',
                            marginBottom: '2rem',
                        }}
                    >
                        {additionalMessage}
                    </p>
                )}
                <p
                    style={{
                        fontSize: '1.6rem',
                        color: '#666',
                    }}
                >
                    Please check the URL or go back to the <a href="/search">dashboard</a>.
                </p>
            </div>
        </div>
    );
}

export default ErrorPage;
