import {LOGS_URL} from './routes-api';
import {callApiWithJwtToken} from "./api";

export const setErrorLog = async (obj: any) => {
	return callApiWithJwtToken({
		url: LOGS_URL + 'SaveErrorLog',
		body: obj,
		needsAuth: false,
		method: 'POST'
	});
};
export const saveUserLog = async (obj: any) => {
	return callApiWithJwtToken({
		url: LOGS_URL + 'SaveUserLog',
		body: obj,
		needsAuth: false,
		method: 'POST'
	});
};

export const saveDebugLog = async (obj: any) => {
	return callApiWithJwtToken({
		url: LOGS_URL + 'SaveDebugLog',
		body: obj,
		needsAuth: false,
		method: 'POST'
	});
};

export const sendEmail = async (obj: any) => {
	return callApiWithJwtToken({
		url: LOGS_URL + 'SendEmail',
		body: obj,
		needsAuth: false,
		method: 'POST'
	});
};

export const saveGraphLog = async (obj: any) => {
	return callApiWithJwtToken({
		url: LOGS_URL + 'SaveGraphLog',
		body: obj,
		needsAuth: true,
		method: 'POST'
	});
};