import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { marginPixels } from '../../../style/General';

function Dashboard(props) {
	console.log('dashboard props', props)
	const { viewsAvailable } = props;
	const { Function_Details } = viewsAvailable[0];
	return (
		<Button
			tag={Link}
			to={Function_Details}
			style={marginPixels.five}
		>
			Dashboard
		</Button>
	);
}

export default Dashboard;