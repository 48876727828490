import {callApiWithJwtToken} from "./api";
import {LISTS_URL} from "./routes-api";

export const getLists = async (obj: any) => {
    return callApiWithJwtToken({
        url: LISTS_URL + 'GetLists',
        body: obj,
        needsAuth: false,
        method: 'POST'
    });
};