import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryView from "./components/helper-components/error-boundary/MainErrorBoundary";

import "bootstrap/dist/css/bootstrap.css";
import "react-datetime/css/react-datetime.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const logError = (error: any, info: any) => {
  // Do something with the error, e.g. log to an external API
  console.log(error, info);
};

root.render(
  <React.StrictMode>
    <ErrorBoundary fallbackRender={ErrorBoundaryView} onError={logError}>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
