import React, {useEffect, useState} from 'react';
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Button,
    CardBody
} from 'reactstrap';
import classnames from 'classnames';
import DoseTableComponent from "./DoseTableComponent";
import {DoseTable, DoseTables, PKDrugModel} from "../../../../model/types";
import {initTCI} from "../../../../model/initTCI";
import {createDoseTableEntries, getMinDiff} from "../../../../helpers/med-helpers";
import {DateTimePicker} from "../../../helper-components/misc/DateTimePicker";
import { externalList } from '../../../shared-components/disclaimer-captcha';

interface DoseSectionProps {
    medTables: DoseTables;
    referenceTime: Date;
    onReferenceTimeChange: (value: Date) => void;
    onChange: (medTables: DoseTables) => void;
    listData: {[index: string]:any};
    medsList: {[index: string]:any};
    drugModels: PKDrugModel[];
    lockedDoseTable: boolean;
    setMedicationModalContext: (drug: string) => void;
    style?: React.CSSProperties;
    LogUserActivity: (medTablesNew: any) => void;
}

const DoseSection: React.FC<DoseSectionProps> = ({
    medTables,
    referenceTime,
    onReferenceTimeChange,
    listData,
    medsList,
    onChange,
    drugModels,
    lockedDoseTable,
    setMedicationModalContext, 
    LogUserActivity
}) => {
    const [activeTab, setActiveTab] = useState('');
    const [dropdownDrug, setDropdownDrug] = useState<string>('');
    const [medTablesLocal, setMedTablesLocal] = useState<DoseTables>({});
    const [medTablesMiChart, setMedTablesMiChart] = useState<DoseTables>({});

    useEffect(() => {
        if(Object.keys(medTablesLocal).length === 0 && Object.keys(medTables).length > 0) {
            console.log('initial Set')
            setMedTablesLocal(medTables);
        }
        if(Object.keys(medTablesMiChart).length === 0 && Object.keys(medTables).length > 0) {
            console.log('initial Set2')
            setMedTablesMiChart(structuredClone(medTables));
        }
        if (Object.keys(medTables).length > 0 && activeTab === '') {
            setActiveTab(Object.keys(medTables)[0]);
        }
        if(medsList != null && medsList.length > 0 && dropdownDrug === '') {
            setDropdownDrug(medsList[0]['Medication_Name']);
        }
    }, [medTables, medsList]);

    const toggle = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const handleAddDrugChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDropdownDrug(e.target.value);
    };

    const onAddRow = () => {
        // If the active tab is not in the medTablesLocal, then add it
        if(!Object.keys(medTablesLocal).includes(dropdownDrug)) {
            const model_id_arr_default = drugModels.filter((model: PKDrugModel) => model.Medication_Name.toLowerCase() === dropdownDrug.toLowerCase() && model.default_model);
            const model_id_arr = drugModels.filter((model: PKDrugModel) => model.Medication_Name.toLowerCase() === dropdownDrug.toLowerCase());
            console.log('model_id_arr_default', model_id_arr_default);
            console.log('model_id_arr', model_id_arr);

            let model_id = -1;
            let model_name: string;
            if(model_id_arr_default.length > 0) {
                model_id = model_id_arr_default[0].Model_ID;
                model_name = model_id_arr_default[0].Model_Name;
            } else if(model_id_arr.length > 0) {
                model_id = model_id_arr[0].Model_ID;
                model_name = model_id_arr[0].Model_Name;
            } else {
                model_id = 0;
                model_name = '';
            }

            const model = drugModels.filter((model: PKDrugModel) => model.Model_ID === model_id)[0];

            setMedTablesLocal({
                ...medTablesLocal,
                [dropdownDrug]: {
                    med: dropdownDrug,
                    model_id,
                    model_name,
                    model,
                    tci: initTCI(model),
                    rows: [],
                    failure_reason: null
                }
            });
        }
        setActiveTab(dropdownDrug);
    }

    const setMedTable = (medTable: DoseTable) => {
        const newMedTables = {
            ...medTablesLocal, // Create a shallow copy of the current state
            [activeTab]: medTable // Update the property for the active tab
        };
        // console.log('newMedTables', newMedTables);
        setMedTablesLocal(newMedTables);
    }

    const setModel = (med: string, model_name: string) => {
        const medTableCopy = medTablesLocal[med];
        medTableCopy.model_name = model_name;
        medTableCopy.model_id = drugModels.filter((model: PKDrugModel) => model.Medication_Name.toLowerCase() === med.toLowerCase() && model.Model_Name === model_name)[0].Model_ID;
        const newMedTables = {
            ...medTablesLocal, // Create a shallow copy of the current state
            [med]: medTableCopy // Update the property for the active tab
        };
        console.log('newMedTables', newMedTables);
        setMedTablesLocal(newMedTables);
        onSave(newMedTables);
    }

    const onSave = (tables: DoseTables) => {
        console.log('tables', tables)
        const medTablesNew = structuredClone(tables);

        // Do Cleanup
        Object.keys(medTablesNew).forEach((med) => {
            // Reset every table tci to initTCI
            medTablesNew[med].tci = initTCI(medTablesNew[med].model);
            // Sort rows based off of time in HH:mm format assume that anesRefTime is the most recent time. If a time before that is found it is the next day
            medTablesNew[med].rows = medTablesNew[med].rows.sort((a, b) => {
                if(a.time == null || b.time == null) {
                    return 0;
                }
                return a.time > b.time ? 1 : -1;
            });
        });

        // Do Validation
        let failed = false;
        Object.keys(medTablesNew).forEach((med) => {
            let failedReason: null|string = null;
            // CHECK 1: Check to see if multiple start infusion events
            let infusionRunning = false;
            // console.log('checking Table', med, medTablesNew[med])
            if(medTablesNew[med].rows.length === 0) {
                failedReason = `Inconsistent medication documentation, cannot calculate graphs`;
            }
            if(failedReason === null) {
                medTablesNew[med].rows.forEach((row) => {
                    // console.log('checking row', row, 'infusionRunning', infusionRunning, 'failedReason', failedReason)
                    if (row.event === 'Start Infusion') {
                        if (infusionRunning) {
                            failedReason = `Can only have one infusion running at a time`;
                        }
                        infusionRunning = true;
                    }
                    if (row.event === 'Stop Infusion' || row.event === 'Dose Change') {
                        if (!infusionRunning) {
                            failedReason = `Infusions must be started with a "Start Infusion" event`;
                        }
                    }
                    if (row.event === 'Stop Infusion') {
                        infusionRunning = false;
                    }
                });
            }
            if (failedReason !== null) {
                medTablesNew[med].failure_reason = failedReason;
                failed = true;
            } else {
                medTablesNew[med].failure_reason = null;
            }
        });

        console.log('failed', failed)

        // for updating reference time
        Object.keys(medTablesNew).forEach((med) => {
            // Reset every table tci to initTCI
            for (let row of medTablesNew[med].rows) {
                row.timeSinceFirstEvent = getMinDiff(row.time, medTablesNew[med].rows[0].time);
            }
            medTablesNew[med].tci = initTCI(medTablesNew[med].model);
        });
        onChange(medTablesNew);
        if (externalList.includes(window.location.host)) {
            LogUserActivity(medTablesNew);
        }
        setMedTablesLocal(medTablesNew);
    }

    const onRemoveMed = (med: string) => {
        const newMedTables = medTablesLocal;
        delete newMedTables[med];
        setMedTablesLocal(newMedTables);
    }

    const updateReferenceTime = (time: any) => {
        console.log('TIMEVALREC', time)
        onReferenceTimeChange(new Date(time))
        onSave(medTablesLocal);
    }

    return (
        <div style={{backgroundColor: 'lightgreen', minHeight: '200px', padding: '20px', flex: '1'}} className='tw-rounded-lg'>
            <h2>Dose History</h2>
            <Row className="mb-3">
                <Col className="col-5">
                    <FormGroup>
                        <div style={{display: 'flex'}}>
                            <Label style={{marginRight: '10px'}} size={'1'} for="referenceTime">Graph Start Time: </Label>
                            <DateTimePicker
                                value={referenceTime}
                                onChange={updateReferenceTime}
                                disabled={false}
                            />
                        </div>
                    </FormGroup>
                </Col>
                <Col className='col-7'>
                    <div style={{display: 'flex', float: 'right'}}>
                        {!lockedDoseTable &&
                            <>
                                <Input
                                    type="select"
                                    value={dropdownDrug}
                                    onChange={handleAddDrugChange}
                                    style={{'marginRight': '5px'}}
                                    id={"med-inputs"}
                                >
                                    {medsList != null && medsList.map((drug: {Medication_Name: string}) => (
                                        <option key={drug.Medication_Name} value={drug.Medication_Name}>{drug.Medication_Name}</option>
                                    ))}
                                </Input>
                                <Button style={{width: '150px'}} color="primary" onClick={onAddRow} id='add-med-button'>Add Med</Button>
                            </>
                        }
                        {
                            lockedDoseTable &&
                            // Round div
                            <div style={{display: 'flex'}}>
                                <div style={{margin: 'auto 0'}}>History & Data Last Updated At:</div>
                                <div style={{marginLeft: '10px', backgroundColor: 'white', padding: '10px', borderRadius: '25px'}}>
                                    <strong>{new Date().toLocaleDateString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}</strong>
                                </div>
                            </div>
                        }
                    </div>
                </Col>
            </Row>
            {/*Rounded padding */}
            <div style={{backgroundColor: 'white', padding: '5px', borderRadius: '5px'}}>
                { Object.keys(medTablesLocal).length === 0 &&
                    <h1>{lockedDoseTable ? 'Document Med Administrations in MiChart' : 'Add a med to begin'}</h1>
                }
                { Object.keys(medTablesLocal).length > 0 &&
                    <>
                        <Nav tabs>
                            {
                                Object.keys(medTablesLocal).map((med) => (
                                    <NavItem key={med}>
                                        <NavLink
                                            className={classnames({active: activeTab === med})}
                                            style={medTablesLocal[med].failure_reason !== null ? {backgroundColor: 'red', color: 'white'} : {}}
                                            onClick={() => {
                                                toggle(med);
                                            }}
                                        >
                                            {med}
                                        </NavLink>
                                    </NavItem>
                                ))
                            }
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            {
                                Object.keys(medTablesLocal).map((med, index) => (
                                    <TabPane key={med} tabId={med}>
                                        <>
                                            <CardBody>
                                                <div style={{display: 'flex'}}>
                                                    <b className='tw-mt-3'>Model: </b>
                                                    <Input
                                                        type="select"
                                                        value={medTablesLocal[med].model_name}
                                                        onChange={(e) => setModel(med, e.target.value)}
                                                        disabled={false}
                                                        className='tw-m-1'
                                                    >
                                                        {drugModels.filter(drug => {
                                                            return drug.Medication_Name.toLowerCase() === med.toLowerCase()
                                                        })
                                                            .map((model: { Model_Name: string }) => (
                                                                <option key={model.Model_Name}
                                                                        value={model.Model_Name}>{model.Model_Name}</option>
                                                            ))}
                                                    </Input>
                                                </div>
                                                <Row>
                                                    <Col sm="12">
                                                        <DoseTableComponent
                                                            medTable={medTablesLocal[med]}
                                                            listData={listData}
                                                            setMedTable={setMedTable}
                                                            referenceTime={referenceTime}
                                                            onSave={() => onSave(medTablesLocal)}
                                                            onRemoveMed={onRemoveMed}
                                                            medsList={medsList}
                                                            index={index}
                                                            lockedDoseTable={lockedDoseTable}
                                                            showDelete={true}
                                                            LogUserActivity={LogUserActivity}
                                                        />
                                                    </Col>
                                                </Row>
                                                {   lockedDoseTable &&
                                                    <>
                                                        <Button color="primary" onClick={() => setMedicationModalContext(med)} style={{marginTop: '10px'}}>
                                                            Preview Changes
                                                        </Button>
                                                    </>
                                                }
                                            </CardBody>
                                        </>
                                    </TabPane>
                                ))
                            }
                        </TabContent>
                    </>
                }
            </div>
            <div style={{marginBottom: '20px'}}/>
        </div>

    );
};

export default DoseSection;