import CustomModal from "../../../layouts/CustomModal";
import React, {useEffect, useState} from "react";
import {CloseButton, SplitBySides} from "../../../../style/General";
import Graph from "./Graph";
import {Button, Card, CardBody, CardHeader, Col, Container, Label, Row} from "reactstrap";
import DoseTableComponent from "./DoseTableComponent";
import {DoseTable, TCI} from "../../../../model/types";
import {initTCI} from "../../../../model/initTCI";
import processTCI from "../../../../model/processTCI";
import html2canvas from "html2canvas";
import {getMinDiff} from "../../../../helpers/med-helpers";
import {DateTimePicker} from "../../../helper-components/misc/DateTimePicker";
import Cookies from "js-cookie";
import {saveGraphLog} from "../../../../api/logs-api";

interface MedicationGraphModalProps {
    onCancel: () => void;
    medicationContext: {[index: string]:any};
    concentrationTarget: string;
    locked: boolean;
}

export const MedicationGraphModal: React.FC<MedicationGraphModalProps> = ({
    onCancel,
    medicationContext,
    concentrationTarget,
    locked
}) => {
    const [medTableLocal, setMedTableLocal] = useState<DoseTable>()
    const [tciLocal, setTciLocal] = useState<TCI>()
    const [refresh, setRefresh] = useState(false)
    const [apiCallResult, setApiCallResult] = useState({
        bodyText: '',
        headerText: '',
        status: '',
        isInformationalModalOpen: false
    })
    const [localReferenceTime, setLocalReferenceTime] = useState<Date|null>(null)

    const ref = React.createRef<HTMLDivElement>();

    const {
        drug,
        tci,
        tci_unit,
        title,
        drugModels,
        medsList,
        effectSiteStyle,
        plasmaStyle,
        modelName,
        medTable,
        listData,
        sex,
        weightValue,
        weightUnit,
        heightValue,
        heightUnit,
        ageValue,
        ageUnit,
        anesthesiaStart,
        anesthesiaEnd,
        maxTime
    } = structuredClone(medicationContext);

    useEffect(() => {
        console.log('LOAD', medicationContext)
        setMedTableLocal(medTable)
        setTciLocal(tci)
        setRefresh(true)
        setLocalReferenceTime(medicationContext.referenceTime)
    }, []);

    useEffect(() => {
        if(refresh) {
            console.log('REFRESHING GRAPH')
            setRefresh(false)
            refreshData()
        }
    }, [refresh]);
    
    const refreshData = () => {
        if(medTableLocal == null || tciLocal == null || localReferenceTime == null) {
            return;
        }
        let sexVal: 'm' | 'f' = 'm';
        if (sex.toLowerCase() === 'female') {
            sexVal = 'f';
        }
        const pDemographics = {
            weight: parseFloat(weightValue),
            weightUnit,
            height: parseFloat(heightValue),
            heightUnit,
            age: parseFloat(ageValue),
            ageUnit,
            sex: sexVal
        }

        console.log('med table', medTableLocal)

        const localMedTable = structuredClone(medTableLocal);
        localMedTable.rows = localMedTable.rows.filter(row => row.event !== '-- Choose Event --')
        const hasFailedTable = medTableLocal.failure_reason != null;
        console.log('hasFailedTable', hasFailedTable)

        localMedTable.tci = initTCI(medTable.model);
        
        let newTci = { ...tciLocal };

        if (hasFailedTable) {
            console.log('newTciMap', newTci)
            setTciLocal(undefined);
            // setLoading(false);
            return;
        }

        if (anesthesiaStart == null) {
            console.log('aneStartNull')
            newTci = processTCI(localMedTable, pDemographics, maxTime, 0, localReferenceTime);
            setTciLocal(newTci);
            return
        }

        let missingTime = 0;
        if(anesthesiaEnd != null) {
            const lastEventTime = localMedTable.rows[localMedTable.rows.length - 1].time;
            missingTime = getMinDiff(anesthesiaEnd, lastEventTime);
            console.log('missingTime', missingTime)
        } else {
            // num of min since reference time
            const currentTime = new Date();
            const lastEventTime =  localMedTable.rows[localMedTable.rows.length - 1].time

            const endTime = (lastEventTime === null || lastEventTime < currentTime) ? currentTime : lastEventTime;

            // num of min between referenceTime and endTime
            missingTime = getMinDiff(endTime, lastEventTime)
            console.log('missingTime', missingTime, getMinDiff(endTime, lastEventTime))
        }

        console.log('localMedTable', localMedTable, maxTime, missingTime, 0);
        newTci = processTCI(structuredClone(localMedTable), pDemographics, maxTime, missingTime, localReferenceTime);

        console.log('newTci', newTci)
        setTciLocal(newTci);
    }

    const onSave = (newReferenceTime: Date) => {
        if(medTableLocal != null) {
            const newMedTable = structuredClone(medTableLocal)

            newMedTable.tci = initTCI(newMedTable.model)
            newMedTable.rows = newMedTable.rows.sort((a, b) => {
                if(a.time == null || b.time == null) {
                    return 0;
                }
                return a.time < b.time ? -1 : 1;
            });

            // Do Validation
            let failed = false;
            let failedReason: null | string = null;
            // CHECK 1: Check to see if multiple start infusion events
            let infusionRunning = false;
            // console.log('checking Table', med, medTablesLocal[med])
            newMedTable.rows.forEach((row) => {
                // console.log('checking row', row, 'infusionRunning', infusionRunning, 'failedReason', failedReason)
                if (row.event === 'Start Infusion') {
                    if (infusionRunning) {
                        failedReason = `Can only have one infusion running at a time`;
                    }
                    infusionRunning = true;
                }
                if (row.event === 'Stop Infusion' || row.event === 'Dose Change') {
                    if (!infusionRunning) {
                        failedReason = `Infusions must be started with a "Start Infusion" event`;
                    }
                }
                if (row.event === 'Stop Infusion') {
                    infusionRunning = false;
                }
            });
            if (failedReason !== null) {
                newMedTable.failure_reason = failedReason;
                failed = true;
            } else {
                newMedTable.failure_reason = null;
            }

            console.log('failed', failed)

            let newLocalReferenceTime = newReferenceTime != null ? newReferenceTime : localReferenceTime;

            // for updating reference time
            // Reset every table tci to initTCI
            for (let row of newMedTable.rows) {
                row.timeSinceFirstEvent = getMinDiff(row.time, newMedTable.rows[0].time);
            }
            newMedTable.tci = initTCI(newMedTable.model);

            setMedTableLocal(newMedTable)
            console.log('refreshSet')
            setRefresh(true);
        }
    }

    const setMedTable = (medTable: DoseTable) => {
        const newMedTable = {...medTable}
        setMedTableLocal(newMedTable)
    }

    const updateReferenceTime = (time: any) => {
        setLocalReferenceTime(new Date(time))
        onSave(new Date(time));
    }

    const saveAsImage = (drug: string) => {
        // @ts-ignore
        html2canvas(ref.current).then(canvas => {
            const image = canvas.toDataURL('image/png', 1.0);

            // Create a link to download the image
            const downloadLink = document.createElement('a');
            // date in form of yyyy-mm-dd
            const dateStr = new Date().toISOString().split('T')[0];
            downloadLink.href = image;
            downloadLink.download = `${drug}-${dateStr}.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }).catch(err => {
            console.error('Error capturing render as image:', err);
        });
    }

    return (
        <CustomModal
            apiCallResult={apiCallResult}
            modalStyle={{maxWidth: '90%'}}
            headerContent={
                <SplitBySides>
                    <h5>
                        {drug} Model
                    </h5>
                    <CloseButton
                        onClick={onCancel}
                        size={25}
                    />
                </SplitBySides>
            }
            bodyContent={
            localReferenceTime !== null && <div ref={ref}>
                <Container>
                    <Row>
                        <Col xs='12'>
                            {tciLocal != null &&
                                <Graph
                                    tci={tciLocal}
                                    tci_enabled_count={1}
                                    tci_unit={tci_unit}
                                    drug={drug}
                                    drugModels={drugModels}
                                    medsList={medsList}
                                    referenceTime={localReferenceTime}
                                    effectSiteStyle={effectSiteStyle}
                                    plasmaStyle={plasmaStyle}
                                    modelName={modelName}
                                    expandGraph={() => {}}
                                    fullSize={true}
                                    concentrationTarget={concentrationTarget}
                                    maxTime={maxTime}
                                />
                            }
                        </Col>
                    </Row>
                    <Row style={{marginTop: '10px'}}>
                        <Card style={{width: '50%', margin: 'auto auto'}}>
                            <CardBody>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Label style={{marginRight: '10px'}} size={'1'} for="referenceTime">Graph Start Time: </Label>
                                <DateTimePicker
                                    disabled={locked}
                                    value={localReferenceTime}
                                    onChange={(date: Date) => updateReferenceTime(date)}
                                />
                            </div>
                            </CardBody>
                        </Card>
                    </Row>
                    <Row style={{marginTop: '10px'}}>
                        <Col xs="12">
                            <Card>
                                <CardHeader>
                                    <h5>Dose History</h5>
                                </CardHeader>
                                <CardBody>
                                    { medTableLocal &&
                                    <DoseTableComponent
                                        medTable={medTableLocal}
                                        listData={listData}
                                        setMedTable={setMedTable}
                                        referenceTime={localReferenceTime}
                                        onSave={() => onSave(localReferenceTime)}
                                        onRemoveMed={() => {}}
                                        medsList={medsList}
                                        index={0}
                                        lockedDoseTable={locked}
                                        startWithNewRow={!locked}
                                        showDelete={!locked}
                                    />
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            }
            footerContent={
                <SplitBySides style={{justifyContent: 'right'}}>
                    <div className={'right'}>
                        <Button
                            color={'success'}
                            onClick={() => saveAsImage(drug)}
                            className='btn btn-primary'
                            style={{marginRight: '10px'}}
                        >
                            Save Image
                        </Button>
                        <Button
                            color={'primary'}
                            onClick={onCancel}
                            className='btn btn-primary'
                        >
                            Close
                        </Button>
                    </div>
                </SplitBySides>
            }
        />
    )
}