import styled from 'styled-components';

export const StepSection = styled.div`
	background-color: white;
	border-radius:5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 8px 0px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px -2px;
	padding:5px;
	margin-bottom: 10px;
`

export const StepTitleContainer = styled.div`	
  font-size: larger;
  font-weight: bold;
  text-align: left;
  padding: 10px;
`