import _ from 'lodash';
import moment from 'moment';
import {catchErrors, tryCatchWrapper} from '../components/helper-components/error-boundary/catch-errors';

import Cookies from "js-cookie";
import {saveDebugLog} from "../api/logs-api";

export function rand(): string {
	return (Date.now() + '' + Math.random()).replace('.', '');
}
export const addElementToArray = (arr: any[], newElement: any) => [...arr, newElement];
export const isItemInList = (list: any[], item: any) => list.includes(item);
export const formatDate = (date: string | number | Date) => {
	return moment(new Date(date)).format('MM/D/YYYY');
};

export const formatDateTime = (date: string | number | Date) => {
	return date ? moment(new Date(date)).format('MM/D/YYYY hh:mm') : '';
};

export function getObjectsList(response: any) {
	const func = (response: { oReturnObject: any; }) => {
		response = processResponse(response)
		const { oReturnObject } = response;
		return oReturnObject;
	};
	return tryCatchWrapper(func, `getObjectsList`, `response`, response);
}

export function getViewsAvailable(response: any) {
	const func = (response: { oReturnObject: any; }) => {
		response = processResponse(response)
		const { oReturnObject } = response;
		const { ViewsFunctions } = oReturnObject;
		return _(ViewsFunctions).value();
	};
	return tryCatchWrapper(func, `getViewsAvailable`, `${processResponse(response)['oReturnObject']}`, response);
}

export const processResponse = (response: { oReturnObject: any; hasOwnProperty?: any; }) => {
	// console.log(response)
	if (response != null && typeof response === 'object' && response.hasOwnProperty('oReturnObject') && response.oReturnObject != null && typeof response.oReturnObject === 'string') {
		// console.log('resp valid')
		response.oReturnObject = JSON.parse(response.oReturnObject);
	}
	return response
}

// Round to 4 significant digits but no more than 3 decimal points unless it's a whole number
export function setCookie(key: string, value: object|string, stringify: boolean) {
	// Set the cookie with the JWT token
	// console.log('savingCookie', key, value, stringify)
	if(!stringify && value != null && typeof value === 'string') {
		Cookies.set(key, value.toString(), {secure: true, sameSite: 'strict'});
	} else {
		Cookies.set(key, JSON.stringify(value), {secure: true, sameSite: 'strict'});
	}
}

export function getCookie(key: string, parse: boolean) {
	// Set the cookie with the JWT token
	// console.log('getCookie', Cookies.get(), key, parse)
	if(!parse) {
		// console.log('noParse', key, Cookies.get(key))
		if(Cookies.get(key) == null) {
			return null;
		}
		return Cookies.get(key)
	} else {
		// console.log('parse', key, Cookies.get(key))
		if(Cookies.get(key) == null) {
			return null;
		}
		// console.log('nonnull', JSON.parse(Cookies.get(key)))
		return JSON.parse(Cookies.get(key) as string)
	}
}

export function removeCookie(key: string) {
	// Set the cookie with the JWT token
	Cookies.remove(key);
}

export function logAuthDebugError(userID: any, location: any, error: string) {
	saveDebugLog({
		Action_User: userID,
		Action_Category: 'AUTHENTICATION_FAILURE',
		Action_Location: location,
		Action_Context: error,
		Additional_Context: '',
	}).catch((err) => {
		catchErrors(err, '', JSON.stringify({userID, location, error}), '');
	});
}