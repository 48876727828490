import {isRouteErrorResponse, useRouteError} from "react-router-dom";
import React from "react";
import ErrorPage from "./ErrorPage";
import NavigationBarButtonless from "../../layouts/NavigationBarButtonless";

export default function RouteBoundary() {
    const error = useRouteError();
    console.log('error', error.status);

    if (isRouteErrorResponse(error)) {
        console.log('boundary triggered')
        return (
            <NavigationBarButtonless>
                <ErrorPage error={error} />
            </NavigationBarButtonless>
        );
    }

    console.log('boundary not triggered')
    throw error;
}