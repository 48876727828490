import { setErrorLog } from '../../../api/logs-api';

export const catchErrors = (err, router, props, state) => {
	let errorLog = null;

	console.log(err)
	console.log(err.stack.toString())

	if(router == null) {
		errorLog = {
			CREATED_BY: 'anonymous',
			ERROR_SOURCE:`no location in props`,
			ERROR_DESCRIPTION: err.toString() + err.stack.toString(),
			CONTEXT_PROPS: JSON.stringify(props).replace(/"([^"]+)":/g, '$1:').replace(/(?:\\(.))/g, '$1'),
			CONTEXT_STATE: JSON.stringify(state).replace(/"([^"]+)":/g, '$1:').replace(/(?:\\(.))/g, '$1')
		};
	} else {
		const { location } = router;
		let pathname = null;
		let search = null
		if(location != null) {
			pathname = location.pathname;
			search = location.search;
		} else {
			pathname = 'unknown';
			search = '/path';
		}


		errorLog = {
			CREATED_BY: 'anonymous',
			ERROR_SOURCE:`${pathname}${search}`,
			ERROR_DESCRIPTION: err.toString() + err.stack.toString(),
			CONTEXT_PROPS: JSON.stringify(props).replace(/"([^"]+)":/g, '$1:').replace(/(?:\\(.))/g, '$1'),
			CONTEXT_STATE: JSON.stringify(state).replace(/"([^"]+)":/g, '$1:').replace(/(?:\\(.))/g, '$1')
		};
	}

	const errorLogPromise = setErrorLog(errorLog);

	// errorLogPromise
	// 	.then((response) => {
	// 		console.log('response', response);
	// 		if(router != null) {
	// 			// this.props.router.navigate(ERROR);
	// 		}
	// 	})
	// 	.catch((err) => {
	// 		// const { router } = this.props;
	// 		// catchErrors(err, router, this.props, this.state);
	// 		if(router != null) {
	// 			console.error(err)
	// 			// this.props.router.navigate(ERROR);
	// 		}
	// 	});
};

export const catchErrorsNegativeSaveResponse = (err, router, props, state) => {
	console.error('err', err);

	const { location } = router;
	const { pathname: Source } = location;

	const errorLog = {
		CREATED_BY: 'anonymous',
		ERROR_SOURCE: Source,
		ERROR_DESCRIPTION: err,
		CONTEXT_PROPS: JSON.stringify(props).replace(/"([^"]+)":/g, '$1:').replace(/(?:\\(.))/g, '$1'),
		CONTEXT_STATE: JSON.stringify(state).replace(/"([^"]+)":/g, '$1:').replace(/(?:\\(.))/g, '$1')
	};

};

export const tryCatchWrapper = (func, props, state, ...arg) => {
	try {
		if (arg) {
			return func.apply(null, arg);
		} else {
			return func();
		}
	} catch (err) {
		const router = null
		console.log(err, func, router, props, state)
		catchErrors(err, router, props, state);
	}
};
