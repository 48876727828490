import React, {useEffect, useState, useRef, Fragment} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import PatientCovariates from './subComponents/PatientCovariates';
import GraphOptions from "./subComponents/GraphOptions";
import Graph from "./subComponents/Graph";
import {getLists} from "../../../api/lists-api";
import {processResponse} from "../../../helpers/helpers";
import {
    getDrugModels,
    getMedications,
} from "../../../api/ehr-api";
import {DoseTables, PKDrugModel, TCI} from "../../../model/types";
import DoseSection from "./subComponents/DoseSection";
import ViewManager from "../../layouts/ViewManager";
import PopoutWindow from './subComponents/PopoutWindow';
import {MedicationGraphModal} from './subComponents/MedicationGraphModal';
import {
    refreshData
} from '../../../helpers/med-helpers';
import {CaptchaDisabledList} from '../../shared-components/disclaimer-captcha';
import {ExternalUserLog} from '../../../api/recaptcha-api';

const Dashboard = () => {
    // Patient Covariates
    const [ageValue, setAgeValue] = useState<string>('');
    const [ageUnit, setAgeUnit] = useState<string>('');
    const [weightValue, setWeightValue] = useState<string>('');
    const [weightUnit, setWeightUnit] = useState<string>('');
    const [heightValue, setHeightValue] = useState<string>('');
    const [heightUnit, setHeightUnit] = useState<string>('');
    const [sex, setSex] = useState<string>('');
    const [patientName, setPatientName] = useState<string>('');
    const [procedureName, setProcedureName] = useState<string>('');
    const [procedureLocation, setProcedureLocation] = useState<string>('');
    const [lockedCovariates, setLockedCovariates] = useState<boolean>(false);
    const [lockedDoseTable, setLockedDoseTable] = useState<boolean>(false);
    // Graph Options
    const [refreshTime, setRefreshTime] = useState<string>(``);
    const [updateRefreshTime, setUpdateRefreshTime] = useState<boolean>(false);
    const [maxTime, setMaxTime] = useState<number>(60);
    const [plasmaStyle, setPlasmaStyle] = useState<string>('Hide');
    const [effectSiteStyle, setEffectSiteStyle] = useState<string>('Show');
    const [concentrationTarget, setConcentrationTarget] = useState<string>('Hide');
    const [listData, setListData] = useState<{ [index: string]: any }>({});
    const [showMedicationModal, setShowMedicationModal] = useState<boolean>(false);
    const [showMedicationExpandModal, setShowMedicationExpandModal] = useState<boolean>(false);
    const [medicationModalContext, setMedicationModalContext] = useState<{ [index: string]: any }>({});
    const [medsList, setMedsList] = useState<{ [index: string]: any }>([]);
    const [tciMap, setTciMap] = useState<{ [index: string]: TCI }>({});
    // Dose Table
    const [referenceTime, setReferenceTime] = useState<Date | null>(null);
    const [medTables, setMedTables] = useState<DoseTables>({});
    const [drugModels, setDrugModels] = useState<PKDrugModel[]>([])
    const [loading, setLoading] = useState<boolean>(false);

    // warning
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [disclaimerOpen, setDisclaimerOpen] = useState<boolean>(false);
    const OnClickDisclaimerButton = (e: any) => {
        window.localStorage.setItem("disclaimer_agreement", "agree");
        setDisclaimerOpen(false);
    }

    // reference for auto scroll down
    const scrollRef = useRef<HTMLDivElement>(null);

    // for auto generating graph
    const [graphList, setGraphList] = useState<Array<string>>([])

    useEffect(() => {
        if (!CaptchaDisabledList.includes(window.location.host)) {
            if (window.localStorage.getItem("disclaimer_agreement") !== "agree") {
                window.location.href = `/disclaimercaptcha`;
            } else if (!window.localStorage.getItem("CAPTCHA_VALUE")) {
                window.location.href = '/disclaimercaptcha'
            }
        }
        load();
    }, []);

    useEffect(() => {
        if (ageValue !== '' && weightValue !== '' && heightValue !== '') {
            console.log('maxTimeRefresh', maxTime)
            console.log('demographicsRefresh', ageValue, ageUnit, weightValue, weightUnit, heightValue)
            setUpdateRefreshTime(true);
            refreshDrugModels(ageValue, ageUnit, weightValue, weightUnit, heightValue, heightUnit, sex, null);
        }
    }, [maxTime]);

    useEffect(() => {
        if (drugModels != null && drugModels.length !== 0 && ageValue !== '' && weightValue !== '' && heightValue !== '') {
            console.log('drugModelsRefresh', drugModels, medTables)
            if (medTables != null && Object.keys(medTables).length !== 0) {
                console.log('drugModelsRefresh2', medTables)

                Object.keys(medTables).forEach((drug) => {
                    const modelID = medTables[drug].model_id;
                    const model = drugModels.find((model) => model.Model_ID === modelID);
                    if (model != null) {
                        medTables[drug].model = model;
                    }
                });

                setMedTables(medTables);

                refreshDataHandler();
            }
        }
    }, [drugModels]);

    useEffect(() => {
        console.log('medTablesRefresh', medTables)
        if (medTables != null && Object.keys(medTables).length !== 0 && ageValue !== '' && weightValue !== '' && heightValue !== '') {
            console.log('medTablesRefresh', medTables)
            refreshDataHandler();
        }
    }, [medTables]);

    useEffect(() => {
        if (updateRefreshTime) {
            const time = new Date().toLocaleDateString('en-US', {hour: '2-digit', minute: '2-digit', hour12: true});
            console.log('refreshTime', time)
            setRefreshTime(time);
            setUpdateRefreshTime(false);
        }
    }, [updateRefreshTime]);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, [tciMap]);

    //////////////////////////////////
    const setDefaultCovariates = () => {
        setAgeValue('30');
        setAgeUnit('years');
        setWeightValue('80');
        setWeightUnit('kg');
        setHeightValue('180');
        setHeightUnit('cms');
        setSex('male');
        refreshDrugModels('30', 'years', '80', 'kg', '180', 'cms', 'male', null);
    }

    const loadMeds = () => {
        getMedications().then(resp => {
            const medsList = processResponse(resp).oReturnObject;
            console.log('medsList', medsList)
            // Loading default loading graphs from DB
            if (graphList.length === 0) {
                setGraphList(medsList.filter((med: {
                    Load_Graph_Default: any;
                }) => med.Load_Graph_Default).map((med: any) => med.Medication_Name));
            }
            // In DB units are all in 1 column need to split infusion vs bolus
            setMedsList(medsList.map((med: any) => (
                // Process which units are infusion vs bolus
                {
                    ...med,
                    Infusion_Units: med.Units.split(',').filter((unit: string) => unit.search('min') >= 0 || unit.search('hr') >= 0),
                    Bolus_Units: med.Units.split(',').filter((unit: string) => unit.search('min') <= 0 && unit.search('hr') <= 0),
                }
            )));
            setLoading(false);
        });
    }

    const load = (override: boolean = false) => {
        setLoading(true);

        setReferenceTime(new Date(new Date().setHours(12, 0, 0, 0)));
        setDefaultCovariates();

        getLists({}).then((res: { bSuccess: boolean, oReturnObject: any }) => {
            console.log('res', res)
            const newData: { [index: string]: any } = {}
            if (res != null && res.bSuccess) {
                const data = processResponse(res).oReturnObject
                if (data.length > 0) {
                    data.forEach((item: { List_Group_Name: string }) => {
                        if (newData.hasOwnProperty(item.List_Group_Name)) {
                            newData[item.List_Group_Name].push(item)
                        } else {
                            newData[item.List_Group_Name] = [item]
                        }
                    });
                    setListData(newData)
                    console.log('listData', newData)
                }
            }
        })

        loadMeds();
    }

    const refreshDrugModels = (age: string, ageU: string, weight: string, weightU: string,
                               height: string, heightU: string, sex: string, medData: any) => {
        console.log('units', age, ageU, weight, weightU, height, heightU, sex);

        getDrugModels({
            age: parseFloat(age) / (ageU.toLowerCase() === 'years' ? 1 : 12),
            height: parseFloat(height) * (heightU.toLowerCase() === 'inches' ? 2.54 : 1),
            weight: parseFloat(weight) * (weightU.toLowerCase() === 'lb' ? 0.4535924 : 1),
            sex: sex === 'male' ? 'm' : 'f'
        }).then(resp => {
            console.log('drugModels', processResponse(resp), medData)
            let drugModels: PKDrugModel[] = processResponse(resp).oReturnObject;

            if (resp.bSuccess) {
                setDrugModels(drugModels);
            } else {
                setModalOpen(true);
                setTciMap({});
            }
        });
    }

    const refreshDataHandler = () => {
        if (referenceTime == null) {
            setLoading(false)
            return;
        }
        const newTCIMap = refreshData(
            sex, weightValue, weightUnit, heightValue, heightUnit, ageValue, ageUnit, medTables,
            tciMap, maxTime, referenceTime
        )
        if (newTCIMap != null) {
            setTciMap(newTCIMap);
        }

        setLoading(false)
    }

    const setMedicationModalContextFunc = (drug: string, expandMode: boolean = false) => {
        setMedicationModalContext({
            // Graph
            tci: tciMap[drug],
            tci_unit: tciMap[drug].pkModel.tci_unit,
            title: refreshTime,
            drug,
            drugModels: drugModels,
            medsList: medsList,
            referenceTime: referenceTime,
            effectSiteStyle: effectSiteStyle,
            plasmaStyle: plasmaStyle,
            modelName: tciMap[drug].pkModel.Model_Name,
            // Dose Section
            medTable: medTables[drug],
            listData: listData,
            sex,
            weightValue,
            weightUnit,
            heightValue,
            heightUnit,
            ageValue,
            ageUnit,
            maxTime
        });
        if (expandMode) {
            setShowMedicationExpandModal(true)
        } else {
            setShowMedicationModal(true);
        }
    }

    const closeMedicationModal = () => {
        setShowMedicationModal(false);
        setShowMedicationExpandModal(false);
        setMedicationModalContext({})
    }

    const expandGraph = (med: string): void => {
        setMedicationModalContextFunc(med, true)
    }

    const LogUserActivity = (medTablesNew: any) => {
        console.log("debug external log", medTablesNew)
        let medTableLog = []

        for (let medName in medTablesNew) {
            medTableLog.push({
                medName: medName,
                rows: medTablesNew[medName].rows,
                referenceTime: referenceTime,
            })
        }

        let logActivity = {
            ageValue: ageValue,
            ageUnit: ageUnit,
            weightValue: weightValue,
            weightUnit: weightUnit,
            heightValue: heightValue,
            heightUnit: heightUnit,
            sex: sex,
            referenceTime: referenceTime,
            medTableLog: JSON.stringify(medTableLog),
            token: window.localStorage.getItem("CAPTCHA_VALUE"),
        }
        console.log("debug external log", logActivity);
        ExternalUserLog(logActivity)
            .then(resp => {
                console.log('debug external log', (resp))
            });
    }

    return (
        referenceTime !== null && <ViewManager
            loading={loading}
            viewContent={
                <>
                    {showMedicationModal &&
                        <MedicationGraphModal
                            onCancel={closeMedicationModal}
                            medicationContext={medicationModalContext}
                            concentrationTarget={concentrationTarget}
                            locked={false}
                        />
                    }

                    {showMedicationExpandModal &&
                        <MedicationGraphModal
                            onCancel={closeMedicationModal}
                            medicationContext={medicationModalContext}
                            concentrationTarget={concentrationTarget}
                            locked={false}
                        />
                    }

                    <div className="tw-grid tw-grid-cols-6 tw-gap-2 tw-mt-3">
                        <div className="tw-col-start-1 tw-col-span-6">
                            <PatientCovariates
                                ageValue={ageValue}
                                setAgeValue={setAgeValue}
                                ageUnit={ageUnit}
                                setAgeUnit={setAgeUnit}
                                weightValue={weightValue}
                                setWeightValue={setWeightValue}
                                weightUnit={weightUnit}
                                setWeightUnit={setWeightUnit}
                                heightValue={heightValue}
                                setHeightValue={setHeightValue}
                                heightUnit={heightUnit}
                                setHeightUnit={setHeightUnit}
                                sex={sex}
                                setSex={setSex}
                                regenerateTitle={() => setUpdateRefreshTime(true)}
                                refreshDrugModels={refreshDrugModels}
                                disabled={lockedCovariates}
                                setDisabled={setLockedCovariates}
                                procedureName={procedureName}
                                procedureLocation={procedureLocation}
                                patientName={patientName}
                                setModalOpen={setModalOpen}
                            />

                        </div>
                        <div className="tw-col-span-6">
                            {/* <div style={{ overflowY: 'auto', maxHeight: '560px' }} ref={scrollRef}> */}
                            <div ref={scrollRef}>

                                <div className="tw-grid tw-grid-cols-6 tw-gap-2 tw-mt-3">
                                    {tciMap != null && Object.keys(tciMap).length !== 0 && Object.keys(tciMap).map(drug => {
                                        const numEnabled = (Object.keys(tciMap).filter(med => graphList.includes(med))).length
                                        return !graphList.includes(drug) ?
                                            // show the graph only if the drug is selected in graph option
                                            // when loaded from michart, only dex, remi, prop, and sufen are automatically selected (if they exist)
                                            <Fragment key={drug}/> :
                                            <div
                                                className={numEnabled === 1 ? 'tw-col-span-6' : numEnabled === 2 ? 'tw-col-span-3' : 'tw-col-span-2'}
                                                key={drug}>
                                                <Graph
                                                    tci={tciMap[drug]}
                                                    tci_enabled_count={(Object.keys(tciMap).filter(med => graphList.includes(med))).length}
                                                    tci_unit={tciMap[drug].pkModel.tci_unit}
                                                    drug={drug}
                                                    drugModels={drugModels}
                                                    medsList={medsList}
                                                    referenceTime={referenceTime}
                                                    effectSiteStyle={effectSiteStyle}
                                                    plasmaStyle={plasmaStyle}
                                                    modelName={tciMap[drug].pkModel.Model_Name}
                                                    expandGraph={expandGraph}
                                                    fullSize={false}
                                                    maxTime={maxTime}
                                                    concentrationTarget={concentrationTarget}
                                                />
                                            </div>
                                    })}

                                </div>

                                {
                                    // If no graphs are enabled, show a message
                                    tciMap != null && Object.keys(tciMap).length !== 0 && !Object.keys(tciMap).some(graph => graphList.includes(graph)) &&
                                    <div style={{textAlign: 'center', marginTop: '200px'}}>
                                        <h1>No Graphs Enabled</h1>
                                        <h2>Go to Graph Options to enable graphs</h2>
                                    </div>
                                }
                                {
                                    (tciMap == null || Object.keys(tciMap).length === 0) &&
                                    <div className="text-center mt-5">
                                        <h3>No Data to Display</h3>
                                        <h4>{lockedDoseTable ? 'Graphs will appear once administrations are documented in MiChart' : 'Please enter some Medications into the Dose History'}</h4>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="tw-col-start-1 tw-col-span-4 tw-rounded-lg"
                             style={{backgroundColor: 'lightgreen'}}>
                            <DoseSection
                                medTables={medTables}
                                referenceTime={referenceTime}
                                onReferenceTimeChange={setReferenceTime}
                                onChange={setMedTables}
                                listData={listData}
                                medsList={medsList}
                                drugModels={drugModels}
                                setMedicationModalContext={setMedicationModalContextFunc}
                                lockedDoseTable={lockedDoseTable}
                                style={{flex: '1'}}
                                LogUserActivity={LogUserActivity}
                            />
                        </div>

                        <div className="tw-col-span-2 tw-rounded-lg" style={{backgroundColor: 'lightblue'}}>
                            <GraphOptions
                                maxTime={maxTime}
                                setMaxTime={setMaxTime}
                                plasmaStyle={plasmaStyle}
                                setPlasmaStyle={setPlasmaStyle}
                                effectSiteStyle={effectSiteStyle}
                                setEffectSiteStyle={setEffectSiteStyle}
                                concentrationTarget={concentrationTarget}
                                setConcentrationTarget={setConcentrationTarget}
                                listData={listData}
                                medTables={medTables}
                                graphList={graphList}
                                setGraphList={setGraphList}
                                style={{flex: '1'}}
                            />
                        </div>
                    </div>
                    {/*<Button color="primary" onClick={() => setShowMPOGModal(true)}>Search By MPOG Case</Button>*/}
                    <br/>
                    {modalOpen ? <PopoutWindow modalOpen={modalOpen} setModalOpen={setModalOpen}/> : <></>}
                    {disclaimerOpen &&
                        <div>
                            <Modal isOpen={true}>
                                <ModalHeader>Disclaimer</ModalHeader>
                                <ModalBody>
                                    <p className='tw-text-lg'>This software performs pharmacokinetic simulations based
                                        on peer-reviewed mathematical models to aid clinicians and researchers in
                                        understanding model implications. It serves as an advisory tool. The data
                                        integration and application of these models to individual patients requires
                                        clinical judgment by healthcare providers. The calculations and information
                                        provided are intended as aids for healthcare professionals and should be
                                        independently verified before making clinical decisions. By using this software,
                                        users release the creators and developers from any liability for direct,
                                        indirect, incidental, consequential, or special damages arising from or in any
                                        way connected with the use of this software or the information provided
                                        herein.</p>
                                    <br/>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" onClick={OnClickDisclaimerButton}>I Agree</Button>
                                </ModalFooter>
                            </Modal>
                        </div>
                    }
                </>
            }
        />
    );
};


export default Dashboard; 