import React from 'react';
import { COMPLETE, REJECTED, WARNING } from '../../helpers/informational-messages/informational-constants';
import CustomModal from './CustomModal';

function ModalInformational(props) {
	const {
		status
		, headerText
		, bodyText
	} = props;

	let headerColor;

	if (status === COMPLETE) {
		headerColor = 'green';
	}
	else if (status === WARNING) {
		headerColor= 'yellow';
	}
	else if (status === REJECTED) {
		headerColor = 'red';
	}

	return (
		<CustomModal
			headerStyle={{ backgroundColor: headerColor}}
			headerContent={
				<>
					<h5>
						{headerText}
					</h5>
				</>
			}
			bodyContent={
				<>
					<div>
						{bodyText}
					</div>
				</>
			}

		/>
		
	);
}

export default ModalInformational;