import { FaTimes } from 'react-icons/fa';
import { DropdownMenu } from 'reactstrap';
import styled from 'styled-components';

export const centerInnerElement = `
	align-items : center;
	display : flex;    
    justify-content: center;
`

export const centerInnerElementInline = {
	alignItems : 'center'
	, display : 'flex'
   , justifyContent: 'center'
}

export const CloseButton = styled(FaTimes)`
	cursor:pointer;
`

export const ColumnsNames = styled.tr`
	background-color: #00274c;
	color: #fff;

	${({ gray }) => gray && `
					background: #ddd !important;	
		color:black;
				`}
`

export const ColumnsValues = styled.tr`
	background-color: white;
	&:hover{
					background-color: cadetblue;
					color: white;
			}
`

export const DropdownList = styled(DropdownMenu)`
	height: 500px;
	overflow-y: scroll;
`

export const flexColumn = `
	display:flex;
  flex-flow:column;
`
export const flexRow = `
	display:flex;
  flex-flow:row;
`
export const flexDirections = {
	column: {
		display: 'flex'
		, flexFlow: 'column'
	},
	row: {
		display: 'flex'
		, flexFlow: 'row'
	}
}

export const flexPercentages = {
	zeroAuto: {
		flex: '0 0 auto'
	},
	zeroThirty: {
		flex: '0 0 30%'
	},
	zeroFifty: {
		flex: '0 0 50%'
	},
	oneAuto: {
		flex: '1 1 auto'
	},
	fifteen: {
		flex: '1 1 15%'
	},
	thirtyFive: {
		flex: '1 1 35%'
	},
	forty: {
		flex: '1 1 40%'
	},
	sixtyFive: {
		flex: '1 1 65%'
	},
	seventy: {
		flex: '1 1 70%',
		marginLeft: '10px',
		marginRight: '10px'
	}
	
}
export const marginPixels = {
	zero: {
		margin:'0px'
	},
	five: {
		margin: '5px'
	},
	fiveTop: {
		marginTop: '5px'
	},
	fiveBottom: {
		marginBottom: '5px'
	},
	fiveExcludingRight: {
		margin:'5px 0px 5px 5px'
	},
	fiveExcludingBottom: {
		margin: '5px 5px 0px 5px'
	},
	fiveLeft: {
		marginLeft: '5px'
	},
	fiveRight: {
		marginRight:'5px'
	},
	fiveLeftRight: {
		margin: '0px 5px 0px 5px'
	},
	ten: {
		margin: '10px'
	},
	fifteen: {
		margin: '15px'
	},
	twenty: {
		margin: '20px'
	},
	thirtyTop: {
		marginTop:'30px'
	},
	oneHundredFiftyRight: {
		marginRight:'150px'
	}
}

export const maxWidth = {
	ninetyPercent: {
		maxWidth:'90%'
	}
	,fifty: {
		maxWidth:'50px'
	}
	,sevenHundred: {
		maxWidth: '700px'
	}
	, nineHundred: {
		maxWidth: '900px'
	}
	,oneThousand: {
		maxWidth: '1000px',
	}
}

export const ModalHeader = styled.div`
	 color: #fff;
		background-color: #00274c;
		padding:15px;
`;

export const ModalRow = styled.div`
	${flexRow}
	justify-content: space-between;
	margin-bottom:5px;
`;

export const PackageContainer = styled.div`
	padding: 20px;
	font-size: 14px;
`;

export const paddingPixels = {
	five: {
		padding: '5px'
	},
	ten: {
		padding: '10px'
	},
	fifteen: {
		padding: '15px'
	},
	twenty: {
		padding: '20px'
	}
}

export const PatientInformationContainer = styled.div`
	display:flex;
	flex-flow:row wrap;
	margin-bottom:5px;
`

export const PatientInformationSubcontainer = styled.div`
	display:flex;
	flex-flow:column;
	flex: 0 0 33%;

`

export const PatientInformationSubsectionTitle = styled.div`
	font-weight: bold;
  margin-bottom:0;
`

export const PatientInformationSubsectionValue = styled.div`
  //background-color: Gray;
  border-radius: 4px;
  //color: White;
  font-weight: bold;
  padding: 6px 12px;
  width: 95%;
  min-height: 46px;
	color:gray;
border: 5px solid transparent;
	border-bottom: 5px solid lightgray;
  ${({ active }) => active && `
		border: 5px solid green;
		//background: green;	
		//color:white;
				`}

	${({ greenBackground }) => greenBackground && `
			background: green;	
			color:white;
					`}
	${({ redbackground }) => redbackground && `
			background: red;	
			color:white;
					`}
`

export const RowValues = styled.tr`
	background-color: white;
	border-color:transparent;
text-align:right;
	&:hover{
		background-color: #eff5f5;
		//color: black;
	}

	${({ inactive }) => inactive && `
					background: red !important;	
		color:white;
				`}
`

export const SearchIconContainer = styled.div`
	display:flex;
	//flex-flow:column;
	background-color: #00274c;
	border-radius: 0.25rem;
	cursor: pointer;
	justify-content: center;
	padding: 5px;
`;

export const Section = styled.div`	
	background-color: white;
	border-radius:5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 8px 0px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px -2px;
	margin-bottom:5px;
	padding:5px;
`

export const SplitBySides = styled.div`
	display: flex;
	flex: 1 1 auto;
	justify-content: space-between;
`

export const LeftSideContainer = styled.div`
	margin-right:5px;
`

export const Tab = styled.div`
	${flexColumn}
	background-color: LightBlue;
	border-radius: 15px 15px 0px 0px;
	color: white;
	cursor:pointer;
	display: flex;
	flex: 1 1 auto;
	justify-content: center;
	text-align:center;
	//margin-left:1px;
	//margin-right:1px;

	${({ inactive }) => inactive && `
					background: lightgray;
					cursor:not-allowed;
				`}

	${({ active }) => active && `
			background: #00274c;			
		`}

	
`;

export const TabNoBorderRadius = styled.div`
	${flexColumn}
	background-color: LightBlue;
	color: white;
	cursor:pointer;
	display: flex;
	flex: 1 1 auto;
	justify-content: center;
	text-align:center;
	//margin-left:1px;
	//margin-right:1px;

	${({ active }) => active && `
			background: #00274c;
		`}
`;

export const TabContainer = styled.div`
	${flexRow}
	height: 35px;
	justify-content: space-around;
`;

export const TabTitle = styled.div`
	margin-top:0px;
`;

export const textDecoration = {
	lineThrough: {
		textDecoration:'line-through'
	}
}

export const WarningAnnouncement = styled.div`
	background-color:red;
	border-radius:5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 8px 0px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px -2px;
	color: white;
	text-align: center;
`

export const widthPercentages = {
	auto: {
		width: 'auto'
	},
	five: {
		width: '5%'
	},
	ten: {
		width: '10%'
	},
	fifteen: {
		width: '15%'
	},
	twenty: {
		width: '20%'
	},
	thirty: {
		width: '30%'
	},
	fifty: {
		width: '50%'
	},
	sixty: {
		width: '60%'
	},
	seventy: {
		width: '70%'
	},
	oneHundred: {
		width:'100%'
	}
	
}

export const widthPixels = {
	five: {
		width: '5px'
	},
	ten: {
		width: '10px'
	},
	fifteen: {
		width: '15px'
	},
	twenty: {
		width: '20px'
	},
	thirty: {
		width: '30px'
	},
	oneHundred: {
		width: '100px'
	}
}