import "./App.css";
import React, { useState } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
import RouteBoundary from "./components/helper-components/error-boundary/RouteBoundary";
import NavigationBarButtonless from "./components/layouts/NavigationBarButtonless";
import { getMainRouteDefinitions } from "./helpers/route-definitions/MainRoutes";
import NavigationBarButtons from "./components/layouts/NavigationBarButtons";
import DisclaimerPopoutWindow from "./components/shared-components/disclaimer";
import DisclaimerCaptcha from "./components/shared-components/disclaimer-captcha";

export default function App(): React.JSX.Element {
  const [viewsAvailable, setViewsAvailable] = useState({});
  const [ipv4Address, setIpv4Address] = useState("");

  const getRouter = () => {
    let data = {
      viewsAvailable,
      ipv4Address,
      setViewsAvailable,
      setIpv4Address,
    };

    const routeDefinitions = [
      ...getMainRouteDefinitions(data),
    ];

    return createBrowserRouter(
      createRoutesFromElements(
        <Route
          // element={<AuthLayout {...data} />}
          // loader={async () => ({ userPromise: getUserData() })}
          errorElement={<RouteBoundary />}
        >
          <Route path="/search" element={<Navigate replace to="/" />} />
          <Route path="/dashboard" element={<Navigate replace to="/" />} />
          <Route path="/disclaimer" element={<DisclaimerPopoutWindow />} />
          <Route path="/disclaimercaptcha" element={<DisclaimerCaptcha />} />
          {routeDefinitions.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                route.navigationButtons == null ? (
                  <>
                    { route.component }
                  </>
                ) : route.navigationButtons ? (
                  <NavigationBarButtons pathname={route.path}>
                    { route.component }
                  </NavigationBarButtons>
                ) : (
                  <NavigationBarButtonless pathname={route.path}>
                    { route.component }
                  </NavigationBarButtonless>
                )
              }
            />
          ))}
        </Route>
      )
    );
  };

  return <RouterProvider router={getRouter()} />;
}
