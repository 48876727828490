import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DisclaimerPopoutWindow from './disclaimer';
import ReCAPTCHA from 'react-google-recaptcha';
import {getCookie, removeCookie} from "../../helpers/helpers";
import {useNavigate} from "react-router-dom";
import { VerifyRecaptcha } from '../../api/recaptcha-api';

const RECAPTCHA_SITE_KEY = (String) (process.env.REACT_APP_RECAPTCHA_SITE_KEY); // Replace with your site key

export const CaptchaDisabledList = [
    "tiva-dev.med.umich.edu", 
    "tiva.med.umich.edu", 
    // "localhost:4200"
];

export const externalList = [
    "tiva-model.org", 
    // "localhost:4200"
]

interface DisclaimerCaptchaComponent {
    // modalOpen: boolean, 
    // setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    className?: string | null
}

const DisclaimerCaptcha: React.FC<DisclaimerCaptchaComponent> = () => {
    const navigate = useNavigate();
    let [captchaSubmitted, setCaptChaSubmitted] = useState<boolean>(!!window.localStorage.getItem("CAPTCHA_VALUE"));
    const [captchaValue, setCaptchaValue] = useState(null);
    const [userIP, setUserIP] = useState("");
    const [userLocation, setUserLocation] = useState({});

    useEffect(() => {
        // Fetch the user's public IP address and location
        const fetchUserIPAndLocation = async () => {
            try {
                // Fetching IP and location using ipapi.co
                const response = await fetch('https://ipapi.co/json');
                const data = await response.json();
                
                setUserIP(data.ip);
                setUserLocation({
                    city: data.city,
                    region: data.region,
                    country: data.country_name,
                });

            } catch (error) {
                console.error('Error fetching IP and location:', error);
            }
        };

        fetchUserIPAndLocation();
    }, []);

    const toggleModal = () => {
        // setModalOpen(!modalOpen);
        setTimeout(() => {
            window.localStorage.setItem("disclaimer_agreement", "agree");
            const redirectUrl = getCookie('redirectPath', false);
            console.log('redirectPath', redirectUrl)
            if(redirectUrl != null) {
                removeCookie('redirectPath');
                console.log('redirecting to', redirectUrl)
                navigate(redirectUrl)
            } else {
                navigate('/')
            }

        }, 500);
    };

    const handleCaptchaChange = (value: any) => {
        setCaptchaValue(value);
        // alert('CAPTCHA completed successfully');
        window.localStorage.setItem("CAPTCHA_VALUE", value);

        VerifyRecaptcha({
            key: value, 
            ip: userIP, 
            location: userLocation
        }).then(resp => {
            console.log('debug recaptcha', (resp))
        });
    };

    const handleSubmit = () => {
        if (!captchaValue) {
            alert('Please complete the CAPTCHA');
            return;
        }

        // Proceed with form submission or other actions
        console.log('CAPTCHA value:', captchaValue);
        alert('CAPTCHA completed successfully');
        window.localStorage.setItem("CAPTCHA_VALUE", captchaValue);
        // window.location.href = `/`;
        setCaptChaSubmitted(true);
    };

    useEffect(() => {

        if (!!window.localStorage.getItem("CAPTCHA_VALUE")) {
            // window.location.href = `/`;
        }
    }, [])

    return (
        <>

            <div>
                <Modal isOpen={true} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>Disclaimer</ModalHeader>
                    <ModalBody>
                        <p className='tw-text-lg'>This software performs pharmacokinetic simulations based on peer-reviewed mathematical models to aid clinicians and researchers in understanding model implications. It serves as an advisory tool. The data integration and application of these models to individual patients requires clinical judgment by healthcare providers. The calculations and information provided are intended as aids for healthcare professionals and should be independently verified before making clinical decisions. By using this software, users release the creators and developers from any liability for direct, indirect, incidental, consequential, or special damages arising from or in any way connected with the use of this software or the information provided herein.</p>
                        <br />
                    </ModalBody>
                    <ModalFooter>

                        {!CaptchaDisabledList.includes(window.location.host)  &&
                            <div>
                                <form onSubmit={handleSubmit}>
                                    <ReCAPTCHA
                                        sitekey={RECAPTCHA_SITE_KEY}
                                        onChange={handleCaptchaChange}
                                    />
                                </form>
                            </div>
                        }

                        {captchaValue === null && !CaptchaDisabledList.includes(window.location.host) ? 
                        <Button color="secondary" onClick={toggleModal} disabled>I Agree</Button> : 
                        <Button color="primary" onClick={toggleModal} >I Agree</Button>}
                    </ModalFooter>
                </Modal>
            </div>
        </>
    )
}

export default DisclaimerCaptcha;

