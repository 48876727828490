import React from 'react';
import { Navbar } from 'reactstrap';
import { SplitBySides } from '../../style/General';

class NavigationBar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false
		};
	}

	render() {
		
		return (
			<Navbar
				expand="md"
				style={{ minHeight: '80px',
					backgroundColor: '#00274c',
					color: 'white'}}
			>	
				<SplitBySides>
					<div className='left'>
						<a href={process.env.PUBLIC_URL +'/search'}>
							<img src={`/main_header_logo.png`} alt='Michigan Medicine logo' height="46px" width="380px" />
						</a>	
					</div>
					<div className='right'>
						{this.props.children}
					</div>
				</SplitBySides>				
			</Navbar>
		);
	}
}

export default NavigationBar;