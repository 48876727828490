import styled from 'styled-components';
import { centerInnerElement } from '../../../style/General';

export const BlueBackgroundContainer = styled.div`
	${centerInnerElement}

	background-color: darkcyan;
    border: 1px solid transparent;
    border-radius: 0.25rem;
	color: white;
	margin:5px;
	padding: 0px 5px 0px 5px;
`;
export const GreenBackgroundContainer = styled.div`
	${centerInnerElement}
	background-color: lightgreen;
    border: 1px solid transparent;
    border-radius: 0.25rem;
	color: white;
	margin:5px;
	padding: 0px 5px 0px 5px;
`;

export const RedBackgroundContainer = styled.div`
	${centerInnerElement}
	background-color: red;
    border: 1px solid transparent;
    border-radius: 0.25rem;
	color: white;
	margin:5px;
	padding: 0px 5px 0px 5px;
`;

export const OrangeBackgroundContainer = styled.div`
	${centerInnerElement}
	background-color: orange;
    border: 1px solid transparent;
    border-radius: 0.25rem;
	color: white;
	margin:5px;
	padding: 0px 5px 0px 5px;
`;