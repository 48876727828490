import React from 'react';
import ReactLoading from 'react-loading';

const loadingGraphicStyle = {
	position: 'absolute',
	zIndex: '20000',
	left: '45%',
	color: 'orange',
	backgroundColor: 'gray',
	top: '10%',
};

const graphicContainerStyle ={
	position:'absolute',
	height:'80%',
	width:'100%',
	zIndex:'10',
	cursor:'wait'
}
export default function LoadingGraphic({ loading }) {
	return (
		<>
			{loading ? (
				<div className='graphic-container' style={graphicContainerStyle}>
					<div className='inner-container'>
						<div className='ref-loading-graphic' style={loadingGraphicStyle}>
							<ReactLoading type='spin' color={'lightblue'} height={'100%'} width={175} />
						</div>
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	);
}
