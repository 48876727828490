import _ from 'lodash';
import React from 'react';
import { tryCatchWrapper } from '../helper-components/error-boundary/catch-errors';
import { flexDirections } from '../../style/General';
import DashboardButton from './Buttons/DashboardButton';
import NavigationBar from './NavigationBarLayout';
import {RedBackgroundContainer} from './StyledComponents/StyleNavigationBar';
import withRouter from "../../helpers/withRouter";

class NavigationBarKitsList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			allComponentsDictionary: { DashboardButton, }, // Admin, QA, Reports
			//, userId
			isOpen: false,
			viewsAvailable: [],
			isAdminDropdownOpen: false,
			adminTaskSelected: {},
		};
	}

	componentDidMount() {
		const func = () => {
			const { mainViewName } = this.props;
			this.loadNavigationBar(mainViewName, null);
		};
		return tryCatchWrapper(func, this.props, this.state);
	}

	componentDidUpdate(prevProps) {
		const func = (prevProps) => {
			const { mainViewName } = this.props;

			if (prevProps.mainViewName !== mainViewName) {
				this.loadNavigationBar(mainViewName, null);
			}
		};
		return tryCatchWrapper(func, this.props, this.state, prevProps);
	}

	loadNavigationBar = (mainViewName, userDetails) => {
		if (_.isEmpty(userDetails)) {
			this.setState({
				viewsProps: {},
				viewsAvailable: [],
			})
		}
	};

	render() {
		const isDEVEnv = window.location.href.includes('-dev') && false;
		const isTESTEnv = window.location.href.includes('-dev');
		const isLOCALEnv = window.location.href.includes('localhost') || window.location.href.includes('-dev');

		return (
			<NavigationBar>
				<div style={flexDirections.row}>
					{isDEVEnv && (
						<RedBackgroundContainer>
							POC/DEV Environment
						</RedBackgroundContainer>
					)}
					{isTESTEnv && (
						<RedBackgroundContainer>
							TST Environment
						</RedBackgroundContainer>
					)}
					{/*{!isDEVEnv && !isTESTEnv && (*/}
					{/*	<OrangeBackgroundContainer>*/}
					{/*		Production*/}
					{/*	</OrangeBackgroundContainer>*/}
					{/*)}*/}
				</div>
			</NavigationBar>
		);
	}
}

export default withRouter(NavigationBarKitsList);

