import {getCookie, processResponse} from "./helpers";
import {initTCI} from "../model/initTCI";
import {DoseTable, DoseTableRow, MedData, mpogAdministration, MPOGMedEvent, PKDrugModel, TCI} from "../model/types";
import processTCI from "../model/processTCI";

export function matchMedication(medName: string, medsListVal: { [index: string]: any; } | null): any {
    let foundMed: any = null;
    if (medsListVal == null) {
        return;
    }
    medsListVal.forEach((tivaMed: { Medication_Search_Terms: string; }) => {
        let foundCount = 0;
        const toFind = tivaMed.Medication_Search_Terms.split(',').length;
        if (!foundMed) {
            tivaMed.Medication_Search_Terms.split(',').forEach((term: string) => {
                if (term[0] === '!') {
                    // blacklisted terms
                    if (medName.indexOf(term.substring(1).toLowerCase()) === -1) {
                        foundCount += 1;
                    }
                } else {
                    // whitelisted terms
                    if (medName.indexOf(term.toLowerCase()) !== -1) {
                        foundCount += 1;
                    }
                }
                if (foundCount === toFind) {
                    foundMed = tivaMed;
                }
            });
        }
    });
    return foundMed;
}

export function createDoseTableEntries(med: any, medsList: { [index: string]: any }, drugModels: PKDrugModel[]): DoseTable|null {
    const { matchedMed } = med;

    // Step 2a: Check if Med is in TIVA
    let medName = matchedMed.code.text.toLowerCase()
    let foundMed: any = null;
    medsList.forEach((drug: any) => {
        let numTermsFound = 0;
        let numTermsToFind = drug['Medication_Search_Terms'].split(',').length;
        // console.log('numTermsToFind', numTermsToFind,drug['Search_Fields'].split(','))
        if (foundMed === null) {
            // let foundTerm = true;
            drug['Medication_Search_Terms'].split(',').forEach((searchField: string) => {
                if (searchField.toLowerCase().charAt(0) === '!') {
                    // blacklisted terms
                    if (medName.indexOf(searchField.substring(1).toLowerCase()) === -1) {
                        // console.log('foundterm', searchField)
                        numTermsFound += 1;
                    }
                } else {
                    // whitelisted terms
                    let anyFound = false
                    searchField.split('|').forEach(searchTerm => {
                        if (medName.indexOf(searchTerm.toLowerCase()) !== -1) {
                            anyFound = true;
                        }
                    });

                    if (anyFound) {
                        // console.log('anyfoundterm', searchField)
                        numTermsFound += 1;
                    }
                }
                if (numTermsToFind === numTermsFound) {
                    // console.log('foundmed', drug)
                    foundMed = drug;
                }
            });
        }
    });

    if (!foundMed) {
        console.log('med not found', medName)
        return null;
    }

    console.log('medMatch', foundMed)

    medName = foundMed['Medication_Name'];

    // Step 2b: Create DoseTable entry
    if (med['MedicationAdministrations'].length === 0) {
        return null;
    }

    const model = drugModels.find((model: PKDrugModel) => model.default_model && model.Medication_Name === medName);
    if (!model) {
        console.log('model not found', medName)
        return null;
    }

    const rows: DoseTableRow[] = [];

    let infusionRunning = false;
    med['MedicationAdministrations'].forEach((admin: any) => {
        let event: string = ''
        const startEvent = admin['Action'] === 'New Bag' || admin['Action'] === 'Infusion Continued' || admin['Action'] === 'Restarted';
        if (!infusionRunning && startEvent) {
            event = 'Start Infusion';
            infusionRunning = true;
        } else if ((infusionRunning && startEvent) || (admin['Action'].search('Change') >= 0)) {
            event = 'Dose Change';
        } else if (admin['Action'] === 'Stopped') {
            event = 'Stop Infusion';
            infusionRunning = false;
        } else if (admin['Action'] === 'Bolus' || admin['Action'] === 'Given') {
            event = 'Bolus';
        }

        if (event === '') {
            console.log('event not found', admin)
            return;
        }

        const adminInstant = new Date(admin['AdministrationInstant']);

        const time: Date = new Date(adminInstant);

        // Num minutes between adminInstant and anesthesiaStart
        let timeSinceFirstEvent = 0;
        if(rows.length > 0 && rows[0].time != null) {
            timeSinceFirstEvent = Math.floor((adminInstant.getTime() - rows[0].time.getTime()) / 60000);
        }

        if(admin['Dose'] != null) {
            rows.push({
                event,
                time,
                timeSinceFirstEvent,
                timeToRun: event === 'Bolus' ? 5.0 / 60.0 : 0,
                dose: parseFloat(admin['Dose']['Value']),
                unit: admin['Dose']['Unit']
            });
        }
    });

    const newMedTable: DoseTable = {
        med: medName,
        model_id: model.Model_ID,
        model_name: model.Model_Name,
        model: model,
        rows,
        tci: initTCI(model),
        failure_reason: null
    }
    console.log('newMedTable', newMedTable)
    return newMedTable;
}

export function refreshData(
    sex: string,
    weightValue: string,
    weightUnit: string,
    heightValue: string,
    heightUnit: string,
    ageValue: string,
    ageUnit: string,
    medTables: { [index: string]: DoseTable },
    tciMap: { [index: string]: TCI },
    maxTime: number,
    referenceTime: Date,
): { [index: string]: TCI}|null {
    let sexVal: 'm' | 'f' = 'm';
    if (sex.toLowerCase() === 'female') {
        sexVal = 'f';
    }
    const pDemographics = {
        weight: parseFloat(weightValue),
        weightUnit,
        height: parseFloat(heightValue),
        heightUnit,
        age: parseFloat(ageValue),
        ageUnit,
        sex: sexVal
    }

    console.log('med table', medTables)

    let localMedTables: {[index: string]: DoseTable} = {};
    // We want to remove tables in a failed state
    Object.keys(medTables).forEach((med) => {
        if(medTables[med].failure_reason === null) {
            localMedTables[med] = medTables[med];
        }
    });

    Object.keys(localMedTables).forEach((med) => {
        // Temporarily Wipe all TCI upon save
        // TODO after save only refresh data after the earliest changed event
        let medTable = localMedTables[med];
        medTable.tci = initTCI(medTable.model);
    });

    const newTciMap = { ...tciMap };

    console.log('localMedTables', localMedTables)
    Object.keys(localMedTables).forEach((med) => {
        console.log('processingMed', med, localMedTables[med])
        newTciMap[med] = processTCI(localMedTables[med], pDemographics, maxTime, 0, referenceTime);
    });

    return newTciMap;
}

export function getMinDiff(time1: Date, time2: Date) {
    console.log('time', time1, time2)
    return (time1.getTime() - time2.getTime()) / 60000;
}

export function prepareMPOGData(mpogData: MPOGMedEvent[], medsList: { [index: string]: any }): MedData|null {
    let medDataObj: MedData = {
        ageUnit: "",
        ageValue: "",
        anesthesiaEnd: null,
        anesthesiaStart: null,
        heightValue: "",
        heightUnit: "",
        medDataValue: [],
        sexValue: "",
        surgicalLog: {},
        weightValue: "",
        weightUnit: "",
        procedureLocation: '',
        procedureName: '',
        patientName: ''
    };

    if(mpogData.length === 0) {
        return null;
    }

    const weight = mpogData[0].weight_kg;
    const weightUnit = 'kg';
    medDataObj.weightValue = weight.toString();
    medDataObj.weightUnit = weightUnit;

    const height = mpogData[0].height_cm;
    const heightUnit = 'cms';
    medDataObj.heightValue = height.toString();
    medDataObj.heightUnit = heightUnit

    medDataObj.sexValue = mpogData[0].aims_sex === 'M' ? 'male' : 'female';

    medDataObj.ageValue = mpogData[0].aims_patient_age_years.toString();
    medDataObj.ageUnit = 'years';

    medDataObj.procedureLocation = mpogData[0].aims_procedure_room_name;
    medDataObj.procedureName = mpogData[0].aims_actual_procedure_text;

    let filteredMeds: MPOGMedEvent[] = [];
    let rejectedMeds: MPOGMedEvent[] = [];

    mpogData.forEach((med: MPOGMedEvent) => {
        const foundMed = matchMedication(med.medication.toLowerCase(), medsList);
        if (foundMed) {
            filteredMeds.push({ ...med, tivaMed: foundMed });
        } else {
            rejectedMeds.push({ ...med, tivaMed: null })
        }
    });

    const medOrg: { [index: string]: MPOGMedEvent[] } = {};

    filteredMeds.forEach((med: MPOGMedEvent) => {
        if (!medOrg.hasOwnProperty(med.tivaMed['Medication_Name'])) {
            medOrg[med.tivaMed['Medication_Name']] = [];
        }
        medOrg[med.tivaMed['Medication_Name']].push(med);
    });

    const anesStart = new Date(filteredMeds[0].anesStart);
    const anesEnd = new Date(filteredMeds[0].anesEnd);
    medDataObj.anesthesiaStart = anesStart;
    medDataObj.anesthesiaEnd = anesEnd;

    console.log('anes', anesStart, anesEnd)

    const medData = Object.keys(medOrg).map((med) => {
        const tivaMed = medOrg[med][0].tivaMed;

        console.log('med', med, tivaMed, medOrg[med])

        const expandedAdministrations: mpogAdministration[] = [];
        medOrg[med].forEach((med: MPOGMedEvent) => {
            if (med.AIMS_Med_UOM.toLowerCase().search('hr') < 0
                && med.AIMS_Med_UOM.toLowerCase().search('min') < 0 ) {
                let bolusEvent: mpogAdministration = {
                    Action: 'Bolus',
                    // remove the ms component of the datetime
                    AdministrationInstant: med.aims_dose_start_dt,
                    Dose: {
                        Value: med.aims_med_dose,
                        Unit: med.AIMS_Med_UOM.toLowerCase()
                    }
                }
                expandedAdministrations.push(bolusEvent);
            } else {
                let startEvent: mpogAdministration = {
                    Action: 'New Bag',
                    AdministrationInstant: med.aims_dose_start_dt,
                    Dose: {
                        Value: med.aims_med_dose,
                        Unit: med.AIMS_Med_UOM.toLowerCase()
                    }
                }
                let endEvent: mpogAdministration = {
                    Action: 'Stopped',
                    AdministrationInstant: med.aims_dose_end_dt,
                    Dose: {
                        Value: 0,
                        Unit: med.AIMS_Med_UOM.toLowerCase()
                    }
                }
                expandedAdministrations.push(startEvent);
                expandedAdministrations.push(endEvent);
            }
        });
        console.log('expandedAdministrations', expandedAdministrations)

        const filteredAdministrations = expandedAdministrations.filter((admin: any) => {
            return (anesStart === null || new Date(admin['AdministrationInstant']) >= anesStart)
                && (anesEnd === null || new Date(admin['AdministrationInstant']) <= anesEnd)
        });

        const expandedRateChangeFix: mpogAdministration[] = [];
        let ignoreFlag = false;
        let infusionRunning = false;
        filteredAdministrations.sort((a: any, b: any) => {
            if(a['AdministrationInstant'] === b['AdministrationInstant']) {
                if(a['Action'] === 'New Bag') {
                    return -1;
                } else if(b['Action'] === 'New Bag') {
                    return 1;
                }
            }
            return new Date(a['AdministrationInstant']).getTime() - new Date(b['AdministrationInstant']).getTime()
        }).forEach((admin: any, index: number) => {
            console.log('admin', index, admin, index < filteredAdministrations.length ? filteredAdministrations[index + 1] : null, ignoreFlag)
            // We want to add if it is last element or is new bag
                    console.log('test',index === filteredAdministrations.length - 1, (admin.Action !== 'New Bag' && !ignoreFlag), admin.Action !== 'New Bag', !ignoreFlag)
            if(!infusionRunning && admin.Action === 'New Bag') {
                infusionRunning = true;
                expandedRateChangeFix.push(admin);
                console.log('0')
            } else if(infusionRunning && admin.Action === 'New Bag'
                && admin.AdministrationInstant === filteredAdministrations[index + 1].AdministrationInstant
            ) {
                ignoreFlag = true;
                admin.Action = 'Dose Change';
                expandedRateChangeFix.push(admin);
                console.log('1')
            } else if(ignoreFlag && admin.Action === 'Stopped') { // && admin.AdministrationInstant === filteredAdministrations[index + 1].AdministrationInstant) {
                console.log('2')
                ignoreFlag = false;
            } else if(admin.Action === 'Stopped') {
                console.log('3')
                infusionRunning = false;
                expandedRateChangeFix.push(admin);
            } else {
                console.log('4')
                expandedRateChangeFix.push(admin);
            }
        });

        if(infusionRunning) {
            console.log('infusionRunning', expandedRateChangeFix)
            let endEvent: mpogAdministration = {
                Action: 'Stopped',
                AdministrationInstant: filteredMeds[0].anesEnd,
                Dose: {
                    Value: 0,
                    Unit: expandedRateChangeFix[expandedRateChangeFix.length - 1].Dose.Unit              }
            }
            expandedRateChangeFix.push(endEvent);
            console.log('withAnesEnd', expandedRateChangeFix)
        }

        console.log('expandedRateChangeFix', expandedRateChangeFix)

        return {
            medicationReference: null,
            basedOn: null,
            extensions: null,
            informationSource: null,
            status: null,
            matchedMed: { code: { text: tivaMed['Medication_Name'] }},
            medConcentration : null,
            tivaMed: tivaMed,
            isActive: null,
            MedicationAdministrations: expandedRateChangeFix.filter((admin: any) =>
                (
                    anesStart === null
                    || new Date(admin['AdministrationInstant']) >= anesStart)
                    && (anesEnd === null || new Date(admin['AdministrationInstant']) <= anesEnd
                )
            ).sort((a: any, b: any) => new Date(a['AdministrationInstant']).getTime() - new Date(b['AdministrationInstant']).getTime())
        }
    });
    console.log('medData', medDataObj);

    medDataObj.medDataValue = medData.filter(med => med != null && med.MedicationAdministrations.length > 0);
    console.log('filteredMeds', filteredMeds, rejectedMeds);

    return medDataObj;
}
