import React from 'react';
import { ModalBody } from 'reactstrap';
import { StepSection, StepTitleContainer } from './StyledComponents/StyleErrorBoundary';


function Unauthorized() {
	return (
		<>
		
			<ModalBody>
				<StepSection>
					<StepTitleContainer>
						<h1>Authorization Error</h1>
						<p>
							You are not authorized to view this page
						</p>
					</StepTitleContainer>
				</StepSection>
			</ModalBody>
		</>
	);
}

export default Unauthorized;
