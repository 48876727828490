import React from 'react';
import { ModalBody } from 'reactstrap';
import NavigationBarButtonless from '../../layouts/NavigationBarButtonless';
import { StepSection, StepTitleContainer } from './StyledComponents/StyleErrorBoundary';

function ErrorBoundaryView() {
	sessionStorage.clear()

	return (
		<NavigationBarButtonless>

			<ModalBody>
				<StepSection>
					<StepTitleContainer>
						<h1>An error has occurred</h1>
						<p>
							Please contact ... MAISE support at pager 1510
						</p>
					</StepTitleContainer>
				</StepSection>
			</ModalBody>
		</NavigationBarButtonless>
	);
}

export default ErrorBoundaryView;

