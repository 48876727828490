import Datetime from 'react-datetime';
import {useEffect, useState} from "react";

interface DateTimePickerProps {
    disabled?: boolean;
    value: Date;
    onChange: (date: any) => void;
}

export function DateTimePicker(props: DateTimePickerProps) {
    const [open, setOpen] = useState(false);
    const valueDate = props.value.getDate();
    const currentDate = (new Date()).getDate();

    useEffect(() => {
        if (open) {
            console.log('scroll')
            window.scrollBy(0, 180);
        }
    }, [open]);

    return (
        <div style={open ? {height: '180px'} : {}} className={'datetime-container'}>
            <Datetime
                initialValue={props.value}
                initialViewMode={'time'}
                inputProps={{disabled: props.disabled}}
                dateFormat={valueDate !== currentDate || open ? 'MM/DD/YYYY' : ''}
                onOpen={() => {
                    setOpen(true )
                }}
                onClose={(moment) => {
                    setOpen(false)
                    props.onChange(moment)
                }}
            />
        </div>
    );
}