import React from 'react';
import PropTypes from 'prop-types';
import ModalInformational from './ModalInformational';
import LoadingGraphic from '../helper-components/misc/LoadingGraphic';

function ViewManager(props) {
	const { apiCallResult = {}, loading, viewContent } = props;
	const { bodyText, headerText, status: informationalStatus, isInformationalModalOpen } = apiCallResult;

	return (
		<>
				<LoadingGraphic loading={loading}/>

			{isInformationalModalOpen && <ModalInformational status={informationalStatus} headerText={headerText} bodyText={bodyText} />}

			<main
				style={{
					display: 'block',

					width: '85%',
					marginLeft: 'auto',
					marginRight: 'auto',
				}}
			>
				{viewContent}
			</main>
		</>
	);
}

ViewManager.propTypes = {
	viewContent: PropTypes.element.isRequired,
	loading: PropTypes.bool,
};

export default ViewManager;
