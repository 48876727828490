import React, {Fragment} from 'react';
import { FormGroup, Label, Input, Row } from 'reactstrap';
import { DoseTables } from "../../../../model/types";

interface GraphOptionsProps {
    maxTime: number;
    setMaxTime: React.Dispatch<React.SetStateAction<number>>;
    plasmaStyle: string;
    setPlasmaStyle: React.Dispatch<React.SetStateAction<string>>;
    effectSiteStyle: string;
    setEffectSiteStyle: React.Dispatch<React.SetStateAction<string>>;
    concentrationTarget: string;
    setConcentrationTarget: React.Dispatch<React.SetStateAction<string>>;
    listData: { [index: string]: any };
    medTables: DoseTables;
    graphList: Array<string>;
    setGraphList: React.Dispatch<React.SetStateAction<Array<string>>>;
    style?: React.CSSProperties;
}

const GraphOptions: React.FC<GraphOptionsProps> = ({
    maxTime,
    setMaxTime,
    plasmaStyle,
    setPlasmaStyle,
    effectSiteStyle,
    setEffectSiteStyle,
    concentrationTarget,
    setConcentrationTarget,
    listData,
    medTables,
    graphList,
    setGraphList
}) => {
    const [localMaxTime, setLocalMaxTime] = React.useState<number>(maxTime);

    const handleClickCheckbox = (med: string) => {
        let newGraphList = [...graphList]
        if (graphList.includes(med)) {
            let index: number = graphList.indexOf(med);
            newGraphList.splice(index, 1)
        }
        else {
            newGraphList.push(med);
        }
        setGraphList(newGraphList)
    }

    return (
        <div style={{ backgroundColor: 'lightblue', minHeight: '200px', padding: '20px' }} className='tw-rounded-lg'>
            <h2>Graph Options</h2>

            <div className='tw-grid tw-grid-cols-6 tw-gap-2 tw-content-stretch'>
                <div className='tw-col-span-3'>
                    <FormGroup>
                        <Label for="effectSiteStyle">Target Effect Site Concentration Range</Label>
                        <Input
                            type="select"
                            id="conenctrationTarget"
                            value={concentrationTarget}
                            onChange={(e) => setConcentrationTarget(e.target.value)}
                        >
                            {listData.hasOwnProperty('GraphOptions-GraphStyle') && listData['GraphOptions-GraphStyle'].map((styleOption: {
                                List_Value: string
                            }) => (
                                <option key={`${styleOption.List_Value}-2`}
                                        value={styleOption.List_Value}>{styleOption.List_Value}</option>

                            ))}
                        </Input>
                    </FormGroup>
                </div>
                <div className='tw-col-span-3'>
                    <FormGroup>
                        <Label for="maxTime">Time (min) Into Future to Generate</Label>
                        <Input
                            type="number"
                            id="maxTime"
                            value={localMaxTime}
                            onChange={(e) => setLocalMaxTime(parseFloat(e.target.value))}
                            onBlur={(e) => setMaxTime(parseFloat(e.target.value))}
                        />
                    </FormGroup>
                </div>
                <div className='tw-col-span-3'>
                    <FormGroup>
                        <Label for="effectSiteStyle">Effect Site Concentration</Label>
                        <Input
                            type="select"
                            id="effectSiteStyle"
                            value={effectSiteStyle}
                            onChange={(e) => setEffectSiteStyle(e.target.value)}
                        >
                            {listData.hasOwnProperty('GraphOptions-GraphStyle') && listData['GraphOptions-GraphStyle'].map((styleOption: {
                                List_Value: string
                            }) => (
                                <option key={`${styleOption.List_Value}-2`}
                                        value={styleOption.List_Value}>{styleOption.List_Value}</option>

                            ))}
                        </Input>
                    </FormGroup>
                </div>
                <div className='tw-col-span-3'>
                    <FormGroup>
                        <Label for="plasmaStyle">Plasma Concentration</Label>
                        <Input
                            type="select"
                            id="plasmaStyle"
                            value={plasmaStyle}
                            onChange={(e) => setPlasmaStyle(e.target.value)}
                        >
                            {listData.hasOwnProperty('GraphOptions-GraphStyle') && listData['GraphOptions-GraphStyle'].map((styleOption: {
                                List_Value: string
                            }) => (
                                <option key={`${styleOption.List_Value}-1`}
                                        value={styleOption.List_Value}>{styleOption.List_Value}</option>

                            ))}
                        </Input>
                    </FormGroup>
                </div>
            </div>

            {Object.keys(medTables).length > 0 &&
                <Row style={{padding: "20px", marginBottom: '20px'}} className='tw-bg-teal-200 tw-rounded-lg'>
                    {!!medTables && (<>
                        <h5>Show Graph</h5>

                        <div className="tw-grid tw-grid-cols-2 tw-gap-0 ">
                            <h6>Generate Graph</h6>
                            <h6>Medications</h6>

                            {Object.keys(medTables).map((med, id) => (
                                <Fragment key={`go-${id}`}>
                                    <div onClick={() => handleClickCheckbox(med)}
                                         className={`tw-flex tw-justify-center tw-border-l tw-border-r tw-border-t ${id === Object.keys(medTables).length - 1 ? "tw-border-b" : ""} tw-p-2 tw-border-sky-500`}>
                                        <Input
                                            type="checkbox"
                                            checked={graphList.includes(med)}
                                            readOnly
                                            style={{ marginRight: '5px', cursor: 'pointer' }}
                                        />
                                    </div>
                                    <div className={`tw-flex tw-justify-normal tw-border-l tw-border-r tw-border-t ${id === Object.keys(medTables).length - 1 ? "tw-border-b" : ""} tw-p-2 tw-border-sky-500`}>
                                        <Label>
                                            {med}
                                        </Label>
                                    </div>
                                </Fragment>
                            ))}
                        </div>
                    </>)}
                </Row>
            }
        </div>
    );
};

export default GraphOptions;
