import React, { useEffect, useState } from 'react';
import {
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Button,
    Accordion,
    AccordionItem,
    AccordionHeader,
    AccordionBody, Card, CardHeader, CardBody
} from 'reactstrap';
import { FaLock } from "react-icons/fa";
import {isValidHeight} from "../restrictions/patient";

interface SubComponent {
    title: string,
    units: Array<string>
}

const SubComponent: React.FC<SubComponent> = ({ title, units }) => {
    return (
        <div className="tw-flex-1 tw-min-w-[100px] tw-flex tw-flex-col sm:tw-flex-col tw-items-stretch">
            <label className="tw-mb-2 tw-font-semibold">{title}</label>
            <input type="text" className="tw-px-2 tw-py-1 tw-mb-2 tw-border tw-rounded-md" />
            <div className="tw-flex tw-flex-row tw-gap-3">
                {units.map((unit, index) => (
                    <label key={index} className="tw-flex tw-items-center tw-mb-1">
                        <input type="radio" className="tw-mr-2" />
                        {unit}
                    </label>
                ))}
            </div>
        </div>
    );
};

interface PatientCovariatesProps {
    ageValue: string;
    setAgeValue: React.Dispatch<React.SetStateAction<string>>;
    ageUnit: string;
    setAgeUnit: React.Dispatch<React.SetStateAction<string>>;
    weightValue: string;
    setWeightValue: React.Dispatch<React.SetStateAction<string>>;
    weightUnit: string;
    setWeightUnit: React.Dispatch<React.SetStateAction<string>>;
    heightValue: string;
    setHeightValue: React.Dispatch<React.SetStateAction<string>>;
    heightUnit: string;
    setHeightUnit: React.Dispatch<React.SetStateAction<string>>;
    sex: string;
    setSex: React.Dispatch<React.SetStateAction<string>>;
    regenerateTitle: () => void;
    refreshDrugModels: (age: string, ageU: string, weight: string, weightU: string, height: string, heightU: string, sex: string, medData: any) => void;
    disabled: boolean;
    setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    procedureLocation: string;
    patientName: string;
    procedureName: string;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PatientCovariates: React.FC<PatientCovariatesProps> = ({
    ageValue,
    setAgeValue,
    ageUnit,
    setAgeUnit,
    weightValue,
    setWeightValue,
    weightUnit,
    setWeightUnit,
    heightValue,
    setHeightValue,
    heightUnit,
    setHeightUnit,
    sex,
    setSex,
    regenerateTitle,
    refreshDrugModels,
    disabled,
    setDisabled,
    procedureLocation,
    patientName,
    procedureName,
    setModalOpen
}) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleAgeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAgeValue(e.target.value);
    };

    const handleAgeUnitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAgeUnit(e.target.value);
    };

    const handleWeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setWeightValue(e.target.value);
    };

    const handleWeightUnitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setWeightUnit(e.target.value);
    };

    const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHeightValue(e.target.value);
    };

    const handleHeightUnitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHeightUnit(e.target.value);
    };

    const handleSexChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSex(e.target.value);
    };

    const handleClickSave = () => {
        if(!isValidHeight(Number(heightValue), heightUnit)) {
            setModalOpen(true);
            return;
        }
        regenerateTitle();
        refreshDrugModels(ageValue, ageUnit, weightValue, weightUnit, heightValue, heightUnit, sex, null);
    }

    const validateHeight = () => {
        if(!isValidHeight(Number(heightValue), heightUnit)) {
            setModalOpen(true);
        }
    }

    return (
        <>

            <div className="tw-flex tw-flex-col tw-rounded-lg" style={{ backgroundColor: 'lightblue' }}>
                {procedureName !== '' &&
                    <div className="tw-flex tw-flex-row tw-p-4 tw-gap-2 tw-mb-0 tw-pb-0">
                        <div className="tw-w-full sm:tw-w-2/12 lg:tw-w-2/12 tw-p-2 tw-flex tw-gap-3 tw-flex-row tw-font-semibold tw-text-3xl">
                            Patient Info
                        </div>

                        <div className="tw-grid tw-grid-cols-4 tw-gap-2 tw-w-3/4">
                            {patientName !== '' &&
                                <div className="tw-w-full tw-p-2 tw-col-span-2 sm:tw-col-span-2 md:tw-col-span-2 lg:tw-col-span-2 2xl:tw-col-span-1 tw-flex tw-flex-row tw-gap-1 tw-bg-[#B8B8B8] tw-rounded-lg tw-p-1">
                                    <label className="tw-flex tw-items-center">
                                        <label className=" tw-font-semibold tw-text-lg">
                                            Name
                                        </label>
                                    </label>
                                    <label className="tw-flex tw-items-center tw-w-full">
                                        <input
                                            type="text"
                                            name="patient name"
                                            id="patient name"
                                            value={patientName}
                                            disabled={true}
                                            className="tw-px-2 tw-border tw-rounded-md tw-w-full"
                                        />
                                    </label>
                                </div>
                            }
                            <div className="tw-w-full tw-p-2 tw-col-span-2 sm:tw-col-span-2 md:tw-col-span-2 lg:tw-col-span-2 2xl:tw-col-span-1 tw-flex tw-flex-row tw-gap-1 tw-bg-[#B8B8B8] tw-rounded-lg tw-p-1">
                                <label className="tw-flex tw-items-center">
                                    <label className="tw-font-semibold tw-text-lg">
                                        Location
                                    </label>
                                </label>
                                <label className="tw-flex tw-items-center tw-w-full">
                                    <input
                                        type="text"
                                        name="location"
                                        id="location"
                                        value={procedureLocation}
                                        disabled={true}
                                        className="tw-px-2 tw-border tw-rounded-md tw-w-full"
                                    />
                                </label>
                            </div>
                            <div
                                className="tw-relative tw-inline-block tw-w-full tw-p-2 tw-col-span-4 sm:tw-col-span-4 md:tw-col-span-4 lg:tw-col-span-4 2xl:tw-col-span-2 tw-flex tw-flex-row tw-gap-0 tw-bg-[#B8B8B8] tw-rounded-lg tw-p-1"
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            >
                                <label className="tw-flex tw-items-center">
                                    <label className="tw-font-semibold tw-text-lg">
                                        Procedure
                                    </label>
                                </label>
                                <label className="tw-flex tw-items-center tw-w-full">
                                    <input
                                        type="text"
                                        name="procedure"
                                        id="procedure"
                                        value={procedureName}
                                        disabled={true}
                                        className="tw-px-2 tw-ml-1 tw-border tw-rounded-md tw-w-full"
                                    />
                                </label>

                                {isHovered && (
                                    <div className="tw-absolute tw-bg-gray-200 tw-text-sm tw-px-2 tw-py-1 tw-rounded tw-shadow-xl tw-z-10 tw-whitespace-wrap tw-top-full tw-left-0 tw-mt-1">
                                        {procedureName}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="tw-flex tw-flex-wrap tw-w-1/12">
                        </div>

                    </div>
                }

                <div className="tw-flex tw-flex-row tw-p-4 tw-gap-2 tw-mb-0 tw-mt-0">
                    <div className="tw-w-full sm:tw-w-2/12 lg:tw-w-2/12 tw-p-2 tw-flex tw-gap-3 tw-flex-row tw-font-semibold tw-text-3xl">
                        {procedureName === '' && <div>Patient Info</div>}
                    </div>

                    <div className="tw-grid tw-grid-cols-4 tw-gap-2 tw-w-3/4">
                        <div className="tw-w-full tw-p-2 tw-col-span-2 sm:tw-col-span-2 md:tw-col-span-2 lg:tw-col-span-2 2xl:tw-col-span-1 tw-flex tw-flex-row tw-gap-1 tw-bg-[#92c6ff] tw-rounded-lg tw-p-1">
                            <label className="tw-flex tw-items-center">
                                <label className="tw-font-semibold tw-text-lg">
                                    Age
                                </label>
                            </label>
                            <label className="tw-flex tw-items-center">
                                <input
                                    type="number"
                                    name="age"
                                    id="age"
                                    value={ageValue}
                                    onChange={handleAgeChange}
                                    disabled={disabled}
                                    className="tw-px-2 tw-border tw-rounded-md tw-w-5/6"
                                />
                            </label>

                            <label className="tw-flex tw-items-center">
                                <input
                                    type="radio"
                                    name="ageUnit"
                                    value="years"
                                    checked={ageUnit === 'years'}
                                    onChange={handleAgeUnitChange}
                                    disabled={disabled}
                                    className="tw-mr-2" />
                                Years
                            </label>
                            <label className="tw-flex tw-items-center">
                                <input
                                    type="radio"
                                    name="ageUnit"
                                    value="months"
                                    checked={ageUnit === 'months'}
                                    onChange={handleAgeUnitChange}
                                    disabled={disabled}
                                    className="tw-mr-2" />
                                Months
                            </label>
                        </div>
                        <div className="tw-w-full tw-p-2 tw-col-span-2 sm:tw-col-span-2 md:tw-col-span-2 lg:tw-col-span-2 2xl:tw-col-span-1 tw-flex tw-flex-row tw-gap-1 tw-bg-[#92c6ff] tw-rounded-lg tw-p-1">
                            <label className="tw-flex tw-items-center">
                                <label className="tw-font-semibold tw-text-lg">
                                    Weight
                                </label>
                            </label>
                            <label className="tw-flex tw-items-center">
                                <input
                                    type="number"
                                    name="weight"
                                    id="weight"
                                    value={weightValue}
                                    onChange={handleWeightChange}
                                    disabled={disabled}
                                    className="tw-px-2 tw-border tw-rounded-md tw-w-5/6"
                                />
                            </label>
                            <label className="tw-flex tw-items-center">
                                <input
                                    type="radio"
                                    name="weightUnit"
                                    value="kg"
                                    checked={weightUnit === 'kg'}
                                    onChange={handleWeightUnitChange}
                                    disabled={disabled}
                                    className="tw-mr-2" />
                                kg
                            </label>
                            <label className="tw-flex tw-items-center">
                                <input
                                    type="radio"
                                    name="weightUnit"
                                    value="lb"
                                    checked={weightUnit === 'lb'}
                                    onChange={handleWeightUnitChange}
                                    disabled={disabled}
                                    className="tw-mr-2" />
                                lb
                            </label>
                        </div>
                        <div className="tw-w-full tw-p-2 tw-col-span-2 sm:tw-col-span-2 md:tw-col-span-2 lg:tw-col-span-2 2xl:tw-col-span-1 tw-flex tw-flex-row tw-gap-0 tw-bg-[#92c6ff] tw-rounded-lg tw-p-1">
                            <label className="tw-flex tw-items-center">
                                <label className="tw-font-semibold tw-text-lg">
                                    Height
                                </label>
                            </label>
                            <label className="tw-flex tw-items-center tw-ml-1">
                                <input
                                    type="number"
                                    name="height"
                                    id="height"
                                    value={heightValue}
                                    onChange={handleHeightChange}
                                    onBlur={validateHeight}
                                    disabled={disabled}
                                    className="tw-px-2 tw-border tw-rounded-md tw-w-5/6"
                                />
                            </label>
                            <label className="tw-flex tw-items-center">
                                <input
                                    type="radio"
                                    name="heightUnit"
                                    value="cms"
                                    checked={heightUnit === 'cms'}
                                    onChange={handleHeightUnitChange}
                                    disabled={disabled}
                                    className="tw-mr-2" />
                                cms
                            </label>
                            <label className="tw-flex tw-items-center">
                                <input
                                    type="radio"
                                    name="heightUnit"
                                    value="inches"
                                    checked={heightUnit === 'inches'}
                                    onChange={handleHeightUnitChange}
                                    disabled={disabled}
                                    className="tw-ml-1 tw-mr-1" />
                                inches
                            </label>
                        </div>
                        <div className="tw-w-full tw-col-span-2 sm:tw-col-span-2 md:tw-col-span-2 lg:tw-col-span-2 2xl:tw-col-span-1 tw-p-2 tw-flex tw-flex-row tw-gap-3 tw-bg-[#92c6ff] tw-rounded-lg tw-p-1">
                            <label className="tw-flex tw-items-center">
                                <label className="tw-font-semibold tw-text-lg">
                                    Sex
                                </label>
                            </label>

                            <label className="tw-flex tw-items-center">
                                <input
                                    type="radio"
                                    name="sex"
                                    value="male"
                                    checked={sex === 'male'}
                                    onChange={handleSexChange}
                                    disabled={disabled}
                                    className="tw-mr-2" />
                                Male
                            </label>
                            <label className="tw-flex tw-items-center">
                                <input
                                    type="radio"
                                    name="sex"
                                    value="female"
                                    checked={sex === 'female'}
                                    onChange={handleSexChange}
                                    disabled={disabled}
                                    className="tw-mr-2" />
                                Female
                            </label>
                        </div>
                    </div>

                    <div className="tw-flex tw-flex-wrap tw-w-1/12">
                        {disabled && <Button color="primary" onClick={() => setDisabled(false)}><FaLock /></Button>}
                        {!disabled && <Button color="primary" onClick={handleClickSave}>Save Changes</Button>}
                    </div>

                </div>
            </div>



            {/* <Card style={{ backgroundColor: 'lightblue', marginTop: '10px' }}>
                <CardBody>
                    <div style={{ display: 'flex', alignItems: 'center', verticalAlign: 'center' }}>
                        <h3 style={{ margin: 'auto 0', marginRight: '25px' }}>Patient Covariates</h3>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            verticalAlign: 'center',
                            backgroundColor: '#92c6ff',
                            padding: '3px',
                            borderRadius: '5px',
                            height: '45px'
                        }}>
                            <h5 style={{ margin: 'auto 10px', display: 'flex' }}>Age:</h5>
                            <Input
                                type="number"
                                name="age"
                                id="age"
                                value={ageValue}
                                onChange={handleAgeChange}
                                disabled={disabled}
                                style={{ width: '100px' }}
                            />
                            <div style={{ marginLeft: '10px' }}>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="ageUnit"
                                        value="years"
                                        checked={ageUnit === 'years'}
                                        onChange={handleAgeUnitChange}
                                        disabled={disabled}
                                    />{' '}
                                    Years
                                </Label>
                                <Label check>
                                    <div style={{ marginLeft: '10px' }}>
                                        <Input
                                            type="radio"
                                            name="ageUnit"
                                            value="months"
                                            checked={ageUnit === 'months'}
                                            onChange={handleAgeUnitChange}
                                            disabled={disabled}
                                        />{' '}
                                        Months
                                    </div>
                                </Label>
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            verticalAlign: 'center',
                            backgroundColor: '#92c6ff',
                            padding: '3px',
                            borderRadius: '5px',
                            marginLeft: '10px',
                            height: '45px'
                        }}>
                            <h5 style={{ margin: 'auto 10px', display: 'flex' }}>Weight:</h5>
                            <Input
                                type="number"
                                name="weight"
                                id="weight"
                                value={weightValue}
                                onChange={handleWeightChange}
                                disabled={disabled}
                                style={{ width: '100px' }}
                            />
                            <div style={{ marginLeft: '10px' }}>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="weightUnit"
                                        value="kg"
                                        checked={weightUnit === 'kg'}
                                        onChange={handleWeightUnitChange}
                                        disabled={disabled}
                                    />{' '}
                                    kg
                                </Label>
                                <Label check>
                                    <div style={{ marginLeft: '10px' }}>
                                        <Input
                                            type="radio"
                                            name="weightUnit"
                                            value="lb"
                                            checked={weightUnit === 'lb'}
                                            onChange={handleWeightUnitChange}
                                            disabled={disabled}
                                        />{' '}
                                        lb
                                    </div>
                                </Label>
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            verticalAlign: 'center',
                            backgroundColor: '#92c6ff',
                            padding: '3px',
                            borderRadius: '5px',
                            marginLeft: '10px',
                            height: '45px'
                        }}>
                            <h5 style={{ margin: 'auto 10px', display: 'flex' }}>Height:</h5>
                            <Input
                                type="number"
                                name="height"
                                id="height"
                                value={heightValue}
                                onChange={handleHeightChange}
                                disabled={disabled}
                                style={{ width: '100px' }}
                            />
                            <div style={{ marginLeft: '10px' }}>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="heightUnit"
                                        value="cms"
                                        checked={heightUnit === 'cms'}
                                        onChange={handleHeightUnitChange}
                                        disabled={disabled}
                                    />{' '}
                                    cms
                                </Label>
                                <Label check>
                                    <div style={{ marginLeft: '10px' }}>
                                        <Input
                                            type="radio"
                                            name="heightUnit"
                                            value="inches"
                                            checked={heightUnit === 'inches'}
                                            onChange={handleHeightUnitChange}
                                            disabled={disabled}
                                        />{' '}
                                        inches
                                    </div>
                                </Label>
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            verticalAlign: 'center',
                            backgroundColor: '#92c6ff',
                            padding: '3px',
                            borderRadius: '5px',
                            marginLeft: '10px',
                            height: '45px'
                        }}>
                            <h5 style={{ margin: 'auto 10px', display: 'flex' }}>Sex:</h5>
                            <div style={{ marginLeft: '10px' }}>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="sex"
                                        value="male"
                                        checked={sex === 'male'}
                                        onChange={handleSexChange}
                                        disabled={disabled}
                                    />{' '}
                                    Male
                                </Label>
                                <Label check>
                                    <div style={{ marginLeft: '10px' }}>
                                        <Input
                                            type="radio"
                                            name="sex"
                                            value="female"
                                            checked={sex === 'female'}
                                            onChange={handleSexChange}
                                            disabled={disabled}
                                        />{' '}
                                        Female
                                    </div>
                                </Label>
                            </div>
                        </div>
                        <div style={{ marginLeft: '10px' }}>
                            {disabled && <Button color="primary" onClick={() => setDisabled(false)}><FaLock /></Button>}
                            {!disabled && <Button color="primary" onClick={handleClickSave}>Save Changes</Button>}
                        </div>
                    </div>
                </CardBody>
            </Card> */}
        </>

    );
};

export default PatientCovariates;
