import React from 'react';
import ReactLoading from 'react-loading';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { flexDirections, maxWidth, ModalHeader } from '../../style/General';
import ModalInformational from './ModalInformational';

const loadingGraphicStyle = {
	position: 'absolute'
	, zIndex: '2'
	, left: '45%'
	//, height: '100%'
	//, width: '375px'
	, color: 'orange'
	, backgroundColor: 'gray'
};

function CustomModal(props) {
	const {
		apiCallResult = {}
		, footerContent
		, headerStyle
		, loading
		, modalStyle = maxWidth.nineHundred
		, modalBodyStyle = {}
	} = props;
	const {
		bodyText
		, headerText
		, status: informationalStatus
		, isInformationalModalOpen
	} = apiCallResult;

	const style = modalStyle ? modalStyle : maxWidth.nineHundred;

	return (
		<Modal
			autoFocus={false}
			isOpen={true}
			style={modalStyle}
		>
			{
				loading &&
				<div
					className='ref-loading-graphic'
					style={loadingGraphicStyle}
				>
					<ReactLoading type='spin' color={'lightblue'} height={'100%'} width={175} />
				</div>
			}		
			{
				isInformationalModalOpen &&
				<ModalInformational
					status={informationalStatus}
					headerText={headerText}
					bodyText={bodyText}
				/>
			}
			<ModalHeader
				style={headerStyle}
			>
				{props.headerContent}
			</ModalHeader>
			<ModalBody
				style={modalBodyStyle}
			>
				<div
					style={flexDirections.column}	
				>
					{props.bodyContent}
				</div>
			</ModalBody>
			{
				footerContent &&
				<ModalFooter>
					{props.footerContent}
				</ModalFooter>
			}
			
		</Modal>
	);
}

export default CustomModal;